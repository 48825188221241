.content-text {
  .container {
    @include mq($from: 'm') {
      display: flex;
      justify-content: space-between;
    }
  }

  .content-text-wrapper {
    width: 100%;
  }

  figure {
    flex-shrink: 0;
    width: 100%;

    @include mq($until: 'm') {
      margin-top: 40px;
    }

    @include mq($from: 'm') {
      max-width: 282px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  p {
    margin-top: 0;

    + p {
      margin-top: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    + p {
      margin-top: 30px;

      @media print {
        margin-top: 10px;
      }
    }
  }
}

.content-text--right {
  figure {
    @include mq($from: 'm') {
      margin-left: 60px;
    }
  }
}

.content-text--left {
  .container {
    flex-direction: row-reverse;
  }

  figure {
    @include mq($from: 'm') {
      margin-right: 60px;
    }
  }
}
