.site-header {
  @include position(absolute, 0 null null 0);
  @include size(100%, auto);
  z-index: layer(highest);
  color: color(white);

  @media print {
    display: none;
  }

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 97px;
    border-bottom: 1px solid rgba(color(white), .1);

    @include mq($until: 'm') {
      height: 73px;
    }
  }

  &.site-header--light {
    color: color(dark-grey);
  }

  .site-footer-nav-links {
    display: none;

    @include mq($until: 'l') {
      display: flex;
      flex-wrap: wrap;
    }

    a {
      @include font('sans-serif', 'light');
      color: color(white);
    }
  }
}

.site-header-logo {
  display: block;
  width: 100%;
  max-width: 202px;

  @include mq($until: 'm') {
    max-width: 135px;
  }

  img {
    display: block;
    width: 100%;
  }
}

.site-header-nav-close {
  @include position(fixed, 0 0 null null);
  @include size(70px);

  @include mq($from: 'l') {
    display: none;
  }
}

.site-header-nav {
  @include mq($until: 'l') {
    @include position(fixed, 0 null null 0);
    @include padding(80px 50px);
    @include size(100%, 100%);
    display: flex;
    visibility: hidden;
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    transition: opacity .2s $ease-out-quad, visibility .2s $ease-out-quad;
    opacity: 0;
    background: color(dark-green);
  }

  .button {
    margin-top: 10px;
    margin-bottom: auto;
    color: $color-white;
    text-align: center;

    @include mq($from: 's') {
      align-self: flex-start;
    }

    @include mq($from: 'l') {
      display: none;
    }
  }

  .mobile-dropdown-toggler {
    @include mq($from: 'l') {
      margin-left: -17px;
      pointer-events: none;
    }

    @include mq($until: 'l') {
      @include position(relative);
      @include size(33px);
      z-index: 2;
      margin-left: 8px;
      border-radius: 50%;
      background-color: color(cyan);

      .svg {
        margin-left: 0;
        fill: color(white);
      }
    }
  }

  > ul {
    @include mq($from: 'l') {
      display: flex;
      align-items: center;
    }

    &:not(.site-footer-nav-links) {
      > li > a:not(.button) {
        display: inline-block;
        transition: color .25s $ease-out-quad;
        color: currentColor;
        font-size: rem(14px);

        @include mq($until: 'l') {
          color: $color-white;
          font-size: rem(24px);
        }

        // Forced to do it this way
        // scss-lint:disable SelectorDepth
        // scss-lint:disable NestingDepth
        &:hover,
        &:focus {
          color: color(orange);

          @include mq($from: 'l') {
            + button .svg {
              fill: color(orange);
            }
          }
        }
      }
    }
  }

  .item {
    @include position(relative);

    > a {
      @include padding(7px 0);

      @include mq($from: 'l') {
        @include padding(11px 15px);
        text-transform: uppercase;
      }
    }

    &:hover {
      .sub-items-list {
        @include mq($from: 'l') {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .svg {
    @include margin(null null null 4px);
    transition: fill .25s $ease-out-quad;
    fill: color(cyan);
  }

  .sub-items-list {
    @include position(absolute, 100% null null 50%);
    @include padding(32px 40px);
    visibility: hidden;
    max-width: 220px;
    transform: translateX(-50%);
    transition: opacity .2s $ease-out-quad, visibility .2s $ease-out-quad;
    border-radius: 2px;
    opacity: 0;
    background-color: color(white);
    box-shadow: 0 20px 20px 0 rgba(color(black), .1);
    color: color(dark-grey);
    white-space: nowrap;

    @include mq($until: 'l') {
      @include padding(15px 50px);
      @include margin(null -50px);
      display: none;
      max-width: none;
      border-radius: 0;
      box-shadow: none;

      &.is-open {
        @include position(relative, auto null null auto);
        display: block;
        visibility: visible;
        transform: none;
        opacity: 1;
      }
    }

    @include mq($until: 'l') {
      font-size: rem(14px);
    }

    &::before {
      @include position(absolute, -3px null null 50%);
      @include size(8px);
      content: '';
      transform: translateX(-50%) rotate(45deg);
      background-color: color(white);

      @include mq($until: 'l') {
        left: 52px;
        transform: rotate(45deg);
      }
    }

    a {
      @include padding(9px null);
      display: block;
      transition: color .25s $ease-out-quad;
      color: currentColor;
      font-size: rem(14px);
      line-height: 1.52;

      &:hover {
        color: color(orange);
      }
    }
  }
}

.site-header-extras {
  .button {
    @include padding(12px 26px);

    @include mq($until: 'l') {
      display: none;
    }
  }
}

.site-menu-toggler {
  @include size(37px);
  color: currentColor;

  @include mq($from: 'l') {
    display: none;
  }

  .svg {
    @include position(relative, 3px null null null);
    fill: currentColor;
  }
}

.site-header-search-button {
  @include size(37px);
  @include margin(null -10px null 6px);
  color: currentColor;

  .svg {
    @include position(relative, 3px null null null);
    fill: currentColor;
  }

  &:focus {
    color: color(orange);
  }
}

.site-header-search {
  @include position(absolute, 0 null null 0);
  @include size(100%, 206px);
  display: flex;
  visibility: hidden;
  align-items: center;
  transition: opacity .2s $ease-out-quad, visibility .2s $ease-out-quad;
  opacity: 0;
  background-color: color(white);
  box-shadow: 0 20px 20px 0 rgba(color(black), .1);

  @include mq($until: 'l') {
    height: 140px;
  }

  .container {
    @include mq($until: 'l') {
      margin-top: 30px;
    }
  }
}

.search-input-wrapper {
  display: flex;
  border: 1px solid color(grey-border);
  border-radius: 4px;

  form {
    width: 100%;
  }

  input {
    @include font('sans-serif', 'light');
    @include padding(27px 60px);
    width: 100%;
    border: 0;
    border-radius: 0;
    font-size: rem(26px);

    @include mq($until: 'l') {
      @include padding(12px 20px);
      font-size: rem(20px);
    }

    @include mq($until: 'm') {
      font-size: rem(16px);
    }

    &::placeholder {
      color: color(placeholder);
    }
  }

  .search-submit {
    @include position(relative);
    @include padding(null 26px null null);
    @include size(130px, auto);

    @include mq($until: 'l') {
      width: 80px;
      padding-right: 0;
    }

    @include mq($until: 'm') {
      width: 60px;
    }

    &::before {
      @include position(absolute, calc(50% - 20px) null null 0);
      @include size(1px, 40px);
      content: '';
      background-color: color(grey-border);
    }

    .svg {
      transition: fill .25s $ease-out-quad;
      fill: color(cyan);
    }

    &:hover,
    &:focus {
      .svg {
        fill: color(orange);
      }
    }
  }
}

.site-header-search-close {
  @include position(absolute, 10px 10px null null);
  @include size(40px);

  .svg {
    transition: fill .25s $ease-out-quad;
    fill: color(cyan);
  }

  &:hover,
  &:focus {
    .svg {
      fill: color(orange);
    }
  }
}

.search-is-open {
  .site-header-search {
    visibility: visible;
    opacity: 1;
  }
}

.menu-is-open {
  .site-header-nav {
    visibility: visible;
    opacity: 1;
  }
}

.site-header--light {
  background-color: color(white);

  > .container {
    border-bottom-color: #efefef;
  }
}
