.content-cta {
  .container {
    @include mq($from: 'm') {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .headline {
    @include margin(null null 8px null);
    color: color(dark-green);
    font-size: rem(10px);
  }

  figure {
    flex-shrink: 0;
    width: 100%;

    @include mq($until: 'm') {
      margin-bottom: 25px;
    }

    @include mq($from: 'm') {
      max-width: 222px;
    }
  }

  img {
    display: block;
    width: 100%;
  }

  .h4 {
    margin-bottom: 12px;
  }

  p {
    margin-top: 0;
  }

  .button {
    margin-top: 30px;
  }

  .link-only {
    width: 100%;
    text-align: center;
  }
}

.content-cta--left {
  figure {
    margin-left: 30px;
  }
}

.content-cta--right {
  .container {
    flex-direction: row;
  }

  figure {
    margin-right: 30px;
  }
}
