.content-image {
  figure {
    display: block;
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
  }

  figcaption {
    @include margin(22px null null null);
    font-size: rem(12px);

    > strong {
      display: inline-block;
      margin-right: 6px;
      vertical-align: top;
    }

    .svg {
      @include position(relative, 1px null null null);
      margin-right: 7px;
      fill: #c4c4c4;
    }
  }

  p {
    display: inline-block;
  }
}
