.news-listing {
  .news-card {
    @include mq($until: 'l') {
      @include margin(null auto);
    }

    + .news-card {
      margin-top: 65px;
    }
  }
}

.news-listing-list {
  @include position(relative);

  &::before,
  &::after {
    @include size(1px, 100%);
    @include position(absolute, 0 null null 50%);
    content: '';
    border-right: 1px dashed #dadada;

    @include mq($until: 's') {
      display: none;
    }
  }

  &::before {
    @include mq($from: 'l') {
      transform: translateX(-204px);
    }
  }

  &::after {
    @include mq($until: 'l') {
      display: none;
    }

    @include mq($from: 'l') {
      transform: translateX(204px);
    }
  }

  > div {
    @include mq($from: 'l') {
      &:nth-of-type(2) {
        .news-card {
          @include margin(null auto);
        }
      }

      &:last-of-type {
        .news-card {
          margin-left: auto;
        }
      }
    }
  }
}
