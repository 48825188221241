.results {
  @include position(relative);
  @include margin(null auto);
  @include padding(65px null);
  max-width: 1400px;
  background-color: color(white);

  @include mq($until: 'm') {
    @include padding(30px null);
  }
}
