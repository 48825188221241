[template="search"] {
  .page-header {
    @include padding(129px null 0);

    @include mq($until: 'm') {
      padding-top: 80px;
    }

    + .search-blocks,
    + .results {
      margin-top: -168px;
    }

    h2 {
      @include font('sans-serif', 'regular');
      @include margin(40px null null null);
      font-size: rem(24px);
      line-height: 1.5;
      text-align: center;
    }

    &::before {
      content: none;
    }

    .container {
      @include position(relative);
      @include padding(64px null 208px null);

      @include mq($from: 'l') {
        @include padding(null 156px null);
      }

      &::before {
        @include position(absolute, 0 null null 50%);
        @include size(calc(100% + 104px), 100%);
        content: '';
        transform: translateX(-50%);
        opacity: .4;
        background-image: image('dots.png');
        background-size: 64px 64px;
      }
    }

    .search-input-wrapper {
      @include position(relative);
      z-index: 2;
      border: 0;
      border-bottom: 2px solid #043c43;
      border-radius: 0;
      background-color: color(darker-green);
    }

    input {
      @include padding(20px null);
      background-color: transparent;
      color: color(white);

      @include mq($until: 'm') {
        font-size: rem(20px);
      }

      &::placeholder {
        color: rgba(color(white), .2);
      }
    }

    .search-submit {
      &::before {
        background-color: rgba(color(white), .1);
      }

      .svg {
        fill: color(white);
      }
    }
  }
}
