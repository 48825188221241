.button {
  @include padding(14px 34px);
  @include font('sans-serif', 'semibold');
  display: inline-block;
  transition: color .25s $ease-out-quad, background-color .25s $ease-out-quad, border-color .25s $ease-out-quad;
  border: 1px solid color(button-border);
  border-radius: 5px;
  color: currentColor;
  font-size: rem(11px);
  letter-spacing: ls(50);
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;

  @include mq($until: 's') {
    padding-right: 21px;
    padding-left: 21px;
  }

  &:hover {
    border-color: color(orange);
    background-color: color(orange);
    color: color(white);
  }

  &:focus {
    border-color: color(orange);
    color: color(orange);
  }

  &:focus:hover {
    color: white;
  }
}

.button--green {
  background-color: color(dark-green);
}
