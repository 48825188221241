.site-footer {
  display: flex;
  align-items: center;
  border-top: 1px solid #f4f4f4;

  @media print {
    display: none;
  }

  @include mq($from: 'm') {
    height: 160px;
  }

  @include mq($until: 'm') {
    @include padding(60px null);
  }

  .container {
    @include mq($from: 'm') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.site-footer-nav {
  width: 100%;

  @include mq($from: 's') {
    display: flex;
    align-items: center;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.site-footer-nav-links {
  @include mq($until: 's') {
    margin-top: 20px;
  }

  li {
    @include position(relative);
    color: #9d9d9d;
    font-size: rem(12px);

    &:first-child {
      @include mq($until: 's') {
        margin-left: -13px;
      }
    }

    &.copyright {
      @include mq($from: 's') {
        margin-left: 10px;
      }

      @include mq($until: 's') {
        display: block;
        width: 100%;
        margin-top: 12px;
      }
    }

    + li:not(.copyright)::before {
      @include position(absolute, calc(50% - 2px) null null -2px);
      @include size(4px);
      content: '';
      border: 1px solid color(orange);
      border-radius: 50%;
    }

    a {
      @include padding(null 13px);
      display: inline-block;
      transition: color .3s $ease-out-quad;
      color: color(black);
      font-size: rem(14px);

      &:hover,
      &:focus {
        color: color(orange);
      }
    }
  }
}

.site-footer-nav-socials {
  flex-shrink: 0;

  @include mq($from: 's') {
    @include margin(null null null 25px);
  }

  @include mq($until: 's') {
    margin-top: 45px;
  }

  li:first-child {
    @include mq($until: 's') {
      margin-left: -13px;
    }
  }

  a {
    @include padding(null 13px);
    display: inline-block;

    &:hover,
    &:focus {
      .svg {
        fill: color(orange);
      }
    }
  }

  .svg {
    transition: fill .3s $ease-out-quad;
  }
}

.site-footer-logo {
  @include margin(null 30px null null);
  display: block;
  width: 51px;

  @include mq($until: 's') {
    width: 44px;
  }

  img {
    display: block;
    width: 100%;
  }
}

.feder-logo {
  display: block;
  max-width: 228px;

  @include mq($from: 'm') {
    margin-left: 20px;
  }

  @include mq($until: 'm') {
    margin-top: 30px;
  }

  @include mq($from: 's', $until: 'm') {
    margin-left: auto;
  }
}
