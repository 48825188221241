.content-graph {
  .row {
    @include mq($from: 'm') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media print {
      display: flex;
      justify-content: space-between;
    }
  }

  h2 + .row {
    margin-top: 50px;

    @include mq($until: 'm') {
      margin-top: 30px;
    }

    @media print {
      margin-top: 20px;
    }
  }

  .subtitle {
    @include font('sans-serif', 'light');
    font-size: rem(24px);

    @media print {
      font-size: rem(15px);
    }
  }

  .content-wrapper {
    .key-value-list {
      font-size: rem(16px);

      @media print {
        font-size: rem(12px);
      }
    }
  }

  .graph-wrapper {
    width: 100%;

    @include mq($until: 'm') {
      margin-top: 20px;
    }

    @include mq($from: 'm') {
      max-width: 408px;
    }

    @media print {
      max-width: 260px;
    }

    p {
      margin-top: 20px;

      @media print {
        margin-top: 10px;
      }
    }

    strong {
      color: color(title);
    }
  }

  .key-value-list + p {
    margin-top: 10px;
  }

  h2 {
    @include margin(null null 20px null);
    color: color(orange);
    font-size: rem(32px);

    @media print {
      margin-bottom: 10px;
      font-size: rem(18px);
    }
  }

  figure {
    display: block;
    width: 100%;
    border: 1px solid #eaeaea;
  }

  img {
    display: block;
    width: 100%;
  }
}
