.search-blocks {
  @include position(relative);
  @include padding(null null 30px null);

  .container {
    @include mq($from: 'm') {
      display: flex;
      justify-content: space-between;

      .card {
        width: 100%;
        max-width: calc(50% - 16px);
      }
    }

    @include mq($until: 'm') {
      .card {
        + .card {
          margin-top: 40px;
        }
      }
    }
  }

  .card-inner {
    @include mq($from: 'l') {
      @include padding(80px 60px);
    }

    h2 {
      min-height: 0;
    }
  }
}
