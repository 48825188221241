// Kennel Settings
// -----------------------------------------------------------------------------
$base-url: '../assets/';
$base-font-size: 16px;
$base-line-height: 2;


// Overrides Default Color Palette
// -----------------------------------------------------------------------------
$color-black: #000;
$color-grey: #373739;
$color-dark-grey: #222;
$color-light-grey: #e1e1e1;
$color-white: #fff;
$color-dark-green: #054f59;
$color-darker-green: #064a53;
$color-blue: #00f;
$color-cyan: #7dc5b1;
$color-orange: #ff7549;
$green-gradient: linear-gradient(-180deg, $color-dark-green 8%, #00444e 99%);
$outline: 1px solid $color-orange;

$default-colors: (
  title: $color-grey,
  text: $color-black,
  link: $color-blue,

  dark-grey: $color-dark-grey,
  dark-green: $color-dark-green,
  darker-green: $color-darker-green,
  cyan: $color-cyan,
  orange: $color-orange,
  white: $color-white,
  black: $color-black,
  faded-white: rgba($color-white, .4),
  placeholder: rgba($color-dark-grey, .7),
  button-border: $color-cyan,
  grey-border: $color-light-grey,

  social-twitter: #04aceb,
  social-facebook: #3b5998,
  social-linkedin: #0085af,
  social-youtube: #cd201f,
  social-instagram: #e12f67,

  alert-valid: green,
  alert-warning: orange,
  alert-error: red,
);


// Custom select colors
// -----------------------------------------------------------------------------
$custom-select-colors: (
  opener-frame: #fff,
  opener-caret: #000,
  opener-color: #000,
  opener-border: #000,
  panel-frame: #fff,
  panel-border: $color-light-grey,
  optgroup-label-color: #000,
  optgroup-label-frame: #eee,
  optgroup-label-border: #ddd,
  option-color: #000,
  option-frame: #fff,
  option-border: #eee,
  option-focus-color: #000,
  option-focus-frame: rgba($color-cyan, .05),
  option-focus-border: #ddd,
  option-selected-color: #000,
  option-selected-frame: rgba($color-cyan, .05),
  option-selected-border: #ddd,
);


// Grid System
// -----------------------------------------------------------------------------
$gs-container: 980px;


// Layers Manager
// -----------------------------------------------------------------------------
$z-layers: (
  above: 999,
  highest: 60,
  higher: 50,
  high: 40,
  low: 20,
  lower: 10,
  lowest: 0,
  below: -1,
);


// Font Stack Declaration
// -----------------------------------------------------------------------------
// Define font stack
$base-font-stack: (
  // Sans-serif
  sans-serif: (
    light: (
      family: ('SourceSansPro', sans-serif),
      weight: 300,
      style : normal,
      file  : (
        family : 'SourceSansPro',
        path   : 'SourceSansPro-Light',
        formats: (woff woff2)
      ),
    ),
    light-italic: (
      family: ('SourceSansPro', sans-serif),
      weight: 300,
      style : italic,
      file  : (
        family : 'SourceSansPro',
        path   : 'SourceSansPro-LightItalic',
        formats: (woff woff2)
      ),
    ),
    regular: (
      family: ('SourceSansPro', sans-serif),
      weight: 400,
      style : normal,
      file  : (
        family : 'SourceSansPro',
        path   : 'SourceSansPro-Regular',
        formats: (woff woff2)
      ),
    ),
    semibold: (
      family: ('SourceSansPro', sans-serif),
      weight: 600,
      style : normal,
      file  : (
        family : 'SourceSansPro',
        path   : 'SourceSansPro-SemiBold',
        formats: (woff woff2)
      ),
    )
  )
);


// Responsive Settings
// -----------------------------------------------------------------------------
$mq-responsive: true;
$mq-static-breakpoint: l;
$mq-show-breakpoints: (xxs, xs, s, m, l, xl, xxl);
