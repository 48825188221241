[template="get-a-quote"] {
  .page-header {
    @include mq($from: 'm') {
      @include padding(180px null 280px null);
    }
  }

  .get-a-quote-form {
    @include padding(50px null null);
    @include position(relative);
    z-index: 2;
    background-color: color(white);

    @include mq($from: 'm') {
      @include padding(100px null null);
      @include margin(-200px auto null);
      max-width: 96%;
    }

    @include mq($from: 'xxl') {
      max-width: 1440px;
    }
  }

  .ui-form,
  .wpcf7-form {
    @include margin(null auto);
    max-width: 910px;

    .lead {
      margin-bottom: 20px;
      font-size: rem(18px);
    }
  }

  .products-selection {
    @include padding(70px null 50px null);
  }

  .product-fieldset {
    + .product-fieldset {
      margin-top: 80px;
    }
  }

  .product-fieldset-row {
    @include mq($from: 's') {
      display: flex;

      &:first-child {
        justify-content: space-between;
      }

      .form-element {
        max-width: 93px;

        &:first-child {
          max-width: 240px;
        }

        &:nth-child(3) {
          max-width: 225px;
        }
      }
    }

    @include mq($until: 'm', $from: 's') {
      flex-wrap: wrap;

      .form-element {
        width: calc(33% - 10px);
        max-width: 100%;

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3) {
          margin-top: 20px;
        }
      }
    }

    + .product-fieldset-row {
      margin-top: 50px;
    }
  }

  .checkbox-wrapper {
    + .checkbox-wrapper {
      @include mq($from: 's') {
        margin-left: 42px;
      }
    }
  }

  .product-fieldset-header {
    @include margin(null null 45px null);
    @include position(relative);
    @include padding(19px 34px);
    border: 1px solid #dadada;
    background-color: #fafafa;

    .title {
      @include font('sans-serif', 'semibold');
      color: color(title);
      font-size: rem(16px);
      line-height: 1;
    }

    .remove-product {
      @include position(absolute, 0 0 null null);
      @include size(56px);
      color: color(cyan);
      line-height: 0;

      &::before {
        content: '—';
        font-size: rem(10px);
      }
    }
  }

  .add-more-products {
    @include padding(10px null);
    @include margin(40px null null null);
    @include font('sans-serif', 'semibold');
    color: color(cyan);
    font-size: rem(14px);
  }

  [type="submit"] {
    min-width: 167px;
  }
}
