.custom-select {
  @include position(relative);

  &::before {
    @include position(absolute, 25px 26px null null);
    content: '';
    transform: translateY(-50%);
    border-width: 5px 4px 0;
    border-style: solid;
    border-color: color(opener-caret, $custom-select-colors) transparent transparent;
    pointer-events: none;
  }
}

.custom-select-container.is-disabled {
  opacity: .35;
}

.js-custom-select,
.custom-select-opener {
  @include padding(17px 45px 17px 20px);
  @include size(100%, 50px);
  display: block;
  overflow: hidden;
  border: 1px solid color(opener-border, $custom-select-colors);
  border-radius: 0;
  outline: none;
  background: transparent;
  background-color: color(opener-frame, $custom-select-colors);
  color: color(opener-color, $custom-select-colors);
  font-size: 14px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
}

.js-custom-select {
  @include padding(15px null);
}

.custom-select-container select {
  @include position(absolute, 0 null null 0);
  @include size(0);
  @include padding(0);
  visibility: hidden;
  border: 0 none;
}

.custom-select-panel {
  @include position(absolute, 100% null null null);
  @include size(100%, auto);
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: overflow-y .1s .5s;
  background-color: color(panel-frame, $custom-select-colors);
}

.custom-select-container.is-open .custom-select-panel {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid color(panel-border, $custom-select-colors);
}

.custom-select-option {
  @include padding(8px 20px);
  background-color: color(option-frame, $custom-select-colors);
  color: color(option-color, $custom-select-colors);
  cursor: pointer;

  + .custom-select-option {
    border-top: 1px solid color(option-border, $custom-select-colors);
  }
}

.custom-select-option.is-selected {
  background-color: color(option-selected-frame, $custom-select-colors);
  color: color(option-selected-color, $custom-select-colors);

  + .custom-select-option {
    border-color: color(option-selected-border, $custom-select-colors);
  }
}

.custom-select-option.has-focus {
  background-color: color(option-focus-frame, $custom-select-colors);
  color: color(option-focus-color, $custom-select-colors);

  + .custom-select-option {
    border-color: color(option-focus-border, $custom-select-colors);
  }
}

.custom-select-optgroup {
  .custom-select-option {
    padding-left: 40px;
  }

  &::before {
    @include size(100%, auto);
    @include padding(10px 20px);
    content: attr(data-label);
    display: block;
    border-bottom: 1px solid color(optgroup-label-border, $custom-select-colors);
    background-color: color(optgroup-label-frame, $custom-select-colors);
    color: color(optgroup-label-color, $custom-select-colors);
    font-weight: 700;
  }
}
