.ui-form,
.wpcf7-form {
  .form-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include mq($until: 's') {
      + .form-element {
        margin-top: 30px;
      }
    }

    &--error {
      label {
        color: color(alert-error);
      }
    }
  }

  .form-row {
    + .form-row {
      margin-top: 45px;

      @include mq($until: 's') {
        margin-top: 30px;
      }
    }

    &:not(.form-row--double) {
      .form-element + .form-element {
        margin-top: 45px;
      }
    }
  }

  .form-row--double {
    @include mq($from: 's') {
      display: flex;
      justify-content: space-between;

      .form-element {
        max-width: calc(50% - 16px);
      }
    }
  }

  .required {
    &::after {
      content: ' *';
    }
  }

  label {
    @include font('sans-serif', 'semibold');
    @include margin(null null 8px null);
    display: block;
    color: #575757;
    font-size: rem(14px);
    line-height: 1.25;

    a {
      text-decoration: underline;
    }

    small {
      @include font('sans-serif', 'regular');
      display: block;
      color: #8e8e8e;
      font-size: rem(11px);
    }
  }

  // Disabled for clean code with external back-end
  // scss-lint:disable QualifyingElement
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  textarea,
  select {
    @include font('sans-serif', 'regular');
    @include padding(16px 20px);
    @include size(100%, auto);
    min-height: 38px;
    border: 1px solid color(grey-border);
    border-radius: 0;
    background: transparent;
    color: color(black);
    font-size: rem(14px);
    line-height: 1;
    appearance: none;

    &::placeholder {
      @include font('sans-serif', 'regular');
      transition: color .3s $ease-out-quad;
      color: color(placeholder);
      font-size: rem(14px);
    }

    &:focus::placeholder {
      color: rgba(color(placeholder), .35);
    }

    &.wpcf7-not-valid {
      border-color: color(alert-error);
    }
  }

  select {
    line-height: 1.2;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  .wpcf7-form-control-wrap {
    @include position(relative);
    display: block;

    [role="alert"] {
      @include position(absolute, 100% null null 0);
      @include size(100%, auto);
      font-size: rem(12px);
    }
  }

  .phone-wrapper {
    @include position(relative);

    &::before {
      @include position(absolute, 50% null null 0);
      @include size(50px, 26px);
      content: '';
      transform: translateY(-50%);
      border-right: 1px solid color(grey-border);
      background-image: image('icons/phone.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 8px 8px;
      pointer-events: none;
    }

    input {
      padding-left: 66px;
    }
  }

  .select-wrapper {
    @include position(relative);

    select {
      @include padding(null 30px null null);
      height: 52px;
    }

    .is-disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    &::before {
      @include size(11px, 6px);
      @include position(absolute, 50% 20px null null);
      content: '';
      background-image: image('icons/arrow-down.png');
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
    }
  }

  .checkbox-wrapper {
    @include position(relative);

    @include mq($until: 's') {
      + .checkbox-wrapper {
        margin-top: 20px;
      }
    }

    input {
      // Cleanest way
      // scss-lint:disable PlaceholderInExtend
      @extend .u-visually-hidden;

      &:focus + label {
        color: color(orange);
      }
    }

    label {
      @include position(relative);
      @include padding(4px 0 4px 33px);
      margin-bottom: 0;
      cursor: pointer;

      &::before {
        @include position(absolute, 1px null null 0);
        @include size(24px);
        content: '';
        border: 1px solid color(grey-border);
      }

      &::after {
        @include position(absolute, 5px null null 9px);
        @include size(6px, 12px);
        content: '';
        transform: rotate(45deg);
        transition: opacity .2s $ease-out-quad;
        border-right: 1px solid color(white);
        border-bottom: 1px solid color(white);
        opacity: 0;
      }

      &:hover {
        color: color(orange);
      }
    }

    input:checked + label {
      &::before {
        border-color: color(orange);
        background-color: color(orange);
      }

      &::after {
        opacity: 1;
      }
    }
  }

  .radio-wrapper {
    input {
      display: none;
    }

    label {
      // @include font(sans-serif, regular);
      @include position(relative);
      @include padding(4px 0 4px 36px);
      margin-bottom: 0;
      cursor: pointer;

      &::before {
        @include position(absolute, 3px null null 0);
        @include size(18px);
        content: '';
        border: 1px solid rgba(color(black), .2);
        border-radius: 100%;
      }

      &::after {
        @include position(absolute, 7px null null 4px);
        @include size(10px, 10px);
        content: '';
        transition: opacity .2s $ease-out-quad;
        border-radius: 100%;
        opacity: 0;
        background-color: color(black);
      }

      &:hover {
        color: $color-blue;
      }
    }

    input:checked + label {
      &::before {
        border: 1px solid color(black);
      }

      &::after {
        opacity: 1;
      }
    }
  }

  .form-footer {
    @include margin(40px null null null);
    text-align: center;
  }
}

.ui-form--smaller {
  .form-row {
    + .form-row {
      margin-top: 30px;
    }

    &:not(.form-row--double) {
      .form-element + .form-element {
        margin-top: 30px;
      }
    }
  }

  .checkbox-wrapper,
  .radio-wrapper {
    label {
      padding-top: 6px;
      font-size: rem(14px);
    }
  }
}

.form-feedback,
.wpcf7-response-output {
  // @include font(sans-serif, demibold);
  @include margin(20px null null null);

  &--success {
    color: color(alert-valid);
  }

  &--error {
    color: color(alert-error);
  }
}

