.news-card {
  width: 100%;
  max-width: 284px;

  @include mq($until: 'm') {
    flex-shrink: 0;
  }

  @include mq($until: 's') {
    max-width: 360px;
  }

  a {
    display: block;
    width: 100%;

    &:hover,
    &:focus {
      h3 {
        color: color(orange);
      }
    }
  }

  figure {
    @include aspect-ratio(284, 300);
    @include margin(null null 28px null);
    width: 100%;
    background-color: color(dark-green);

    img {
      @include position(absolute, 50% null null 50%);
      @include size(100%, 100%);
      @include object-fit(cover, center);
      display: block;
      transform: translate(-50%, -50%);
    }
  }

  .headline {
    @include margin(null null 10px null);
    color: color(orange);
    font-size: rem(10px);
  }

  h3 {
    @include font('sans-serif', 'regular');
    transition: color .25s $ease-out-quad;
    color: color(black);
    line-height: 1.6;
  }

  p {
    @include margin(10px null null null);
    max-width: 240px;
    color: color(black);
    font-size: rem(16px);
    line-height: 1.87;
  }
}
