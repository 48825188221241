[template="home"] {
  .gradient-wrapper {
    padding-bottom: 245px;
    background: $green-gradient;

    @include mq($until: 'm') {
      padding-bottom: 180px;
    }
  }

  .page-header {
    @include padding(280px null 160px null);
    background: transparent;

    @include mq($from: 'm') {
      &::after {
        @include position(absolute, null null 0 50%);
        @include size(100%, 1px);
        content: '';
        max-width: 1100px;
        transform: translateX(-50%);
        border-bottom: 1px dashed rgba(color(white), .1);
      }
    }

    @include mq($until: 'l') {
      &::after {
        max-width: 90%;
      }
    }

    @include mq($until: 's') {
      @include padding(170px null 120px null);
    }

    &::before {
      @include mq($from: 'm') {
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%) translateX(230px) translateY(50px);
      }
    }

    .headline {
      @include margin(null null 22px null);
    }

    .buttons-wrapper {
      @include mq($until: 375px) {
        .button {
          @include padding(null 10px);
        }

        .button + .button {
          margin-left: 5px;
        }
      }
    }
  }

  .cards-listing {
    margin-top: -200px;
  }
}
