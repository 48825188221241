// Dealing with plugin
// scss-lint:disable SelectorDepth
// scss-lint:disable NestingDepth
.catalogue {
  @include padding(100px null);

  @include mq($until: 'm') {
    @include padding(60px null 0);
  }

  .catalogue-text-wrapper {
    @include mq($from: 'm') {
      display: flex;
      justify-content: space-between;
    }

    p {
      font-size: rem(16px);
    }
  }

  .line {
    @include margin(null 54px);
    flex-shrink: 0;
    width: 1px;
    background-color: color(grey-border);

    @include mq($until: 'm') {
      display: none;
    }
  }

  .catalogue-text-left {
    flex-shrink: 0;
    width: 100%;

    @include mq($from: 'm') {
      max-width: 323px;
    }

    @include mq($until: 'm') {
      margin-bottom: 40px;
    }

    h2 {
      margin-bottom: 20px;
      font-size: rem(40px);
    }
  }

  .orange,
  .blue {
    @include font('sans-serif', 'semibold');
  }

  .catalogue-text-right {
    h3 {
      margin-bottom: 10px;
      font-size: rem(24px);
    }

    p + h3 {
      margin-top: 30px;
    }
  }

  .catalogue-table-wrapper {
    @include margin(85px null 50px null);
    min-height: 360px;
    max-height: 650px;
    overflow: auto;

    @include mq($until: 'm') {
      @include margin(35px null null null);
      height: 90vh;
    }

    thead {
      background-color: white;
    }

    tbody {
      width: 100%;
    }

    table {
      width: 100%;
      min-width: 1100px;
      font-size: rem(13px);

      &.fixed-head .fltrow .custom-select-container {
        visibility: hidden;
      }

      &:not(.fixed-head) thead tr:not(.fltrow) th {
        // &:first-child,
        // &:nth-child(2),
        // &:nth-child(3) {
        //   visibility: hidden;
        //   pointer-events: none;
        // }
      }
    }
  }

  .catalogue-ctas {
    @include margin(90px null null null);

    @include mq($until: 'm') {
      margin-top: 45px;
    }

    @include mq($until: 375px) {
      .button {
        @include padding(null 10px);

        + .button {
          margin-left: 5px;
        }
      }
    }

    a + a {
      margin-left: 9px;
    }
  }

  table.TF .fltrow select {
    padding-bottom: 2px;
    font-weight: 600;
    line-height: 1.25;
  }

  .custom-select-opener {
    @include padding(5px 0);
    @include font('sans-serif', 'semibold');
    height: auto;
    border: 0;
    color: color(title);

    span {
      @include position(relative);
      @include padding(null 30px null null);

      &::before {
        @include size(11px, 6px);
        @include position(absolute, calc(50% - 3px) 10px null null);
        content: '';
        background-image: image('icons/arrow-down.png');
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
      }
    }
  }
}
