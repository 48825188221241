[template="404"] {
  .page-header {
    @include mq($until: 'm') {
      padding-bottom: 140px;
    }

    .subtitle {
      max-width: 340px;
    }
  }
}
