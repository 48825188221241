.article {
  .article-date {
    @include font('sans-serif', 'semibold');
    @include padding(21px null);
    @include margin(null null 50px null);
    border-top: 1px solid color(grey-border);
    border-bottom: 1px solid color(grey-border);
    color: color(dark-green);
    font-size: rem(10px);
    letter-spacing: 2px;
    line-height: 1.05;
    text-transform: uppercase;
  }

  .bottom-button {
    @include margin(50px null null null);
    text-align: center;
  }

  .article-intro {
    @include font('sans-serif', 'light');
    font-size: rem(16px);

    @media print {
      padding-bottom: 30px;
      border-bottom: 1px solid color(grey-border);
      font-size: rem(12px);
    }
  }
}
