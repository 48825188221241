.cta-blocks {
  @include padding(75px null);
  color: color(white);

  @include mq($until: 'm') {
    padding-top: 0;
  }

  .cta-blocks-wrapper {
    @include mq($from: 's') {
      display: flex;
      justify-content: space-between;
    }
  }

  article {
    width: 100%;
    max-width: calc(50% - 22px);

    @include mq($until: 's') {
      max-width: 100%;

      + article {
        margin-top: 70px;
      }
    }
  }

  .headline {
    @include margin(null null 7px null);
    color: rgba(color(white), .4);
  }

  h2 + p {
    margin-top: 50px;

    @include mq($until: 's') {
      margin-top: 40px;
    }
  }
}

.cta-blocks-button {
  @include margin(70px null null null);
  display: flex;
  align-items: center;

  @include mq($from: 's') {
    &::before,
    &::after {
      @include size(100%, 1px);
      content: '';
      display: block;
      border-bottom: 1px dashed rgba(color(white), .1);
    }
  }

  .button {
    flex-shrink: 0;

    @include mq($from: 's') {
      @include margin(null 32px);
    }
  }
}
