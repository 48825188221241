.page-header {
  @include position(relative);
  @include padding(245px null 220px null);
  background: color(dark-green);
  color: color(white);

  @include mq($from: 's') {
    overflow: hidden;
  }

  @include mq($until: 'l') {
    @include padding(185px null 160px null);
  }

  @include mq($until: 's') {
    @include padding(170px null 70px null);
  }

  @media print {
    // Disabled for clean code with external back-end
    // scss-lint:disable ImportantRule
    padding: 40px 0 30px !important;
    color: color(black);

    .headline {
      color: rgba(color(black), .4);
    }
  }

  .container {
    @include position(relative);
  }

  &:not(.page-header--light) {
    &::before {
      @include position(absolute, 50% null null 50%);
      @include size(696px, 651px);
      @include retina-image('page-header/page-header-background', contain);
      content: '';
      display: block;
      transform: translate(-50%, -50%) translateX(230px) translateY(110px);
      opacity: .5;
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;

      @include mq($until: 's') {
        @include size(375px, 350px);
        left: 100%;
        transform: translate(-60%, -50%) translateY(210px);
      }
    }
  }

  .print-button {
    @include mq($until: 'm') {
      margin-top: 30px;
    }

    @include mq($from: 'm') {
      @include position(absolute, null 0 12px null);
      min-width: 120px;
    }

    @media print {
      display: none;
    }
  }

  .headline {
    @include margin(null null 6px null);
    color: rgba(color(white), .4);
  }

  h1 {
    line-height: 1.16;

    @include mq($until: 'l') {
      br {
        display: none;
      }
    }
  }

  .buttons-wrapper {
    @include margin(64px null null null);

    @include mq($until: 375px) {
      .button {
        @include padding(null 10px);

        + .button {
          margin-left: 5px;
        }
      }
    }

    a + a {
      margin-left: 9px;
    }
  }

  p {
    @include margin(20px null null null);
    @include font('sans-serif', 'light');
    max-width: 600px;
    font-size: rem(20px);
    line-height: 1.5;
  }

  .lead {
    @include margin(20px null null null);

    p {
      @include font('sans-serif', 'regular');
      max-width: 100%;
      color: color(title);
      font-size: rem(18px);
      line-height: 2;
    }
  }
}


.page-header + .cards-listing {
  @include mq($from: 'l') {
    @include margin(-200px null null null);
  }

  @include mq($until: 'l') {
    @include margin(-100px null null null);
  }
}


.page-header--light {
  @include padding(180px null 90px null);
  background: transparent;
  color: color(black);

  .headline {
    color: rgba(color(black), .4);
  }

  &::before {
    content: none;
  }
}

.page-header--post {
  padding-bottom: 50px;

  @include mq($until: 'm') {
    padding-top: 80px;
  }

  @include mq($from: 'm') {
    @include position(relative);

    &::before {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      content: '';
      z-index: -2;
      opacity: .24;
      background-image: image('dots.png');
      background-size: 64px 64px;
    }
  }

  h1 {
    color: color(orange);

    @include mq($until: 'm') {
      font-size: rem(40px);
    }
  }

  .container {
    @include padding(40px null null null);
    @include position(relative);

    &::before {
      @include position(absolute, 0 null null -188px);
      @include size(calc(100% + 2*188px), calc(100% + 300px));
      content: '';
      z-index: -1;
      background-color: color(white);
    }
  }
}
