.latest-news {
  @include margin(80px null null null);

  @include mq($from: 'm') {
    @include margin(120px null null null);
  }
}

.latest-news-header {
  @include margin(null null 30px null);
  text-align: center;

  @include mq($from: 's') {
    @include margin(null null 70px null);
  }

  .headline {
    color: #a8a8a8;
  }

  h2 {
    line-height: 1.15;

    @include mq($until: 's') {
      font-size: rem(60px);
    }
  }
}

.latest-news-list {
  display: flex;
  justify-content: space-between;

  .line {
    @include margin(-38px 20px);
    display: block;
    border-right: 1px dashed rgba(color(grey-border), .5);

    @include mq($until: 'm') {
      display: none;
    }
  }
}

.latest-news-footer {
  @include margin(65px null null null);
  text-align: center;

  @include mq($from: 's') {
    @include margin(110px null null null);
  }
}
