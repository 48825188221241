.cards-listing {
  @include position(relative);
  padding-bottom: 25px;

  &::before {
    @include position(absolute, 50px null null 50%);
    @include size(750px, calc(100% - 100px));
    content: '';
    z-index: 0;
    transform: translateX(-50%);
    opacity: .24;
    background-image: image('dots.png');
    background-size: 64px 64px;
  }

  .container {
    @include position(relative);

    @include mq($from: 'm') {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include mq($from: 'l') {
      justify-content: space-between;
    }
  }

  .card {
    width: 100%;
    max-width: 345px;

    @include mq($from: 'l') {
      &:nth-child(n + 4) {
        margin-top: 40px;
      }
    }

    @include mq($until: 'l', $from: 'm') {
      width: calc(50% - 20px);

      &:nth-child(n + 2) {
        margin-top: 20px;
      }

      &:not(.card--placeholder):not(:nth-child(3n)) + .card {
        margin-left: 20px;
      }
    }

    @include mq($until: 'm') {
      @include margin(null auto);

      + .card {
        margin-top: 20px;
      }
    }

    &:nth-child(n + 4) {
      margin-top: 40px;
    }
  }

  .card--placeholder {
    opacity: 0;

    @include mq($until: 'l') {
      display: none;
    }
  }
}

