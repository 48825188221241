.content-quote {
  .svg-quote {
    @include position(relative);
    @include margin(null null 30px 10px);
    fill: color(orange);

    &::before {
      @include size(34px);
      @include position(absolute, 50% null null 50%);
      content: '';
      transform: translate(-50%, -50%);
      border: 1px solid rgba(color(orange), .5);
      border-radius: 50%;
    }
  }

  blockquote {
    @include font('sans-serif', 'light');
    font-size: rem(40px);
    line-height: 1.25;
  }

  cite {
    @include margin(20px null null null);
    @include font('sans-serif', 'semibold');
    display: block;
    font-size: rem(14px);
  }
}
