// Default typography settings, to be included as soon as possible in the HTML
// 1. Make type rendering look crisper
// 2. The default font size is 16px
// 3. Prevent iOS and IE text size adjust after device orientation change, without disabling user zoom.
//
// @requires {variable} $base-font-size
html {
  @include font-smoothing; /* 1 */
  font-size: strip-units($base-font-size) / 16 * 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */ // scss-lint:disable VendorPrefix
  -webkit-text-size-adjust: 100%; /* 3 */ // scss-lint:disable VendorPrefix
}


// Default text styles
// -----------------------------------------------------------------------------
body {
  @include font('sans-serif', 'regular');
  color: color(text);
  font-size: rem(16px);
  line-height: 2;

  @media print {
    font-size: rem(12px);
  }
}

.headline {
  display: block;
  font-size: rem(11px);
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;

  .svg {
    @include margin(null null null 3px);
    fill: color(orange);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font('sans-serif', 'light');
  color: currentColor;

  strong,
  b {
    color: color(orange);
  }
}

h1,
.h1 {
  font-size: rem(60px);
  line-height: 1.25;

  @include mq($until: 's') {
    font-size: rem(45px);
  }

  @media print {
    font-size: rem(36px);
  }
}

h2,
.h2 {
  font-size: rem(50px);
  line-height: 1.3;

  @include mq($until: 'm') {
    font-size: rem(40px);
  }

  @media print {
    font-size: rem(28px);
  }
}

h3,
.h3 {
  font-size: rem(40px);
  line-height: 1.25;

  @include mq($until: 'm') {
    font-size: rem(32px);
  }

  @media print {
    font-size: rem(20px);
  }
}

h4,
.h4 {
  font-size: rem(24px);
  line-height: 1.25;

  @media print {
    font-size: rem(16px);
  }
}

h5,
.h5 {
  font-size: rem(20px);
  line-height: 1.1;

  @media print {
    font-size: rem(14px);
  }
}

h6
.h6 {
  font-size: rem(18px);
  line-height: 1.25;

  @media print {
    font-size: rem(12px);
  }
}

h5,
h6,
.h5,
.h6 {
  @include font('sans-serif', 'regular');
}

strong,
b {
  @include font('sans-serif', 'semibold');
}

em {
  @include font('sans-serif', 'light-italic');
}

p {
  @include font('sans-serif', 'light');

  strong,
  b {
    @include font('sans-serif', 'semibold');
  }

  em {
    @include font('sans-serif', 'light-italic');
  }

  a {
    @include font('sans-serif', 'semibold');
    color: color(cyan);
  }

  + p {
    margin-top: 30px;

    @media print {
      margin-top: 10px;
    }
  }
}



sup {
  font-size: smaller;
  vertical-align: super;
}

sub {
  font-size: smaller;
  vertical-align: sub;
}

.lead {
  @include font('sans-serif', 'regular');
  color: color(title);
  font-size: rem(18px);

  p {
    @include font('sans-serif', 'regular');
    color: color(title);
    font-size: rem(18px);
  }
}

.table-wrapper {
  @include mq($until: 'm') {
    width: 100%;
    height: auto;
    overflow: auto;

    table {
      min-width: 760px;
    }
  }
}

table {
  color: color(title);
  font-size: rem(14px);

  td,
  th {
    padding-right: 5px;
    text-align: left;
  }

  th {
    @include padding(null null 24px null);
    @include font('sans-serif', 'semibold');
    font-size: rem(14px);
  }

  td {
    @include padding(13px null);
  }

  tr {
    border-bottom: 1px solid color(grey-border);

    &:hover {
      background-color: rgba(color(cyan), .05);
    }
  }

  a {
    @include font('sans-serif', 'semibold');
    color: color(cyan);
  }
}

// Disabled because we can't change the classes (plugin)
// scss-lint:disable SelectorFormat
// scss-lint:disable QualifyingElement
table.TF {
  thead tr:not(.fltrow) th {
    backface-visibility: hidden;
    line-height: 1;
    vertical-align: bottom;
    pointer-events: none;
  }

  .fltrow {
    background-color: #eee;

    td {
      @include position(relative, null null null null);
      @include padding(11px 0);
    }

    select {
      @include padding(8px 24px 8px 0);
      @include font('sans-serif', 'regular');
      @include size(100%, auto);
      margin-bottom: 2px;
      backface-visibility: hidden;
      border: 0;
      border-radius: 0;
      background: transparent;
      color: color(title);
      font-size: rem(14px);
      line-height: 1;
      appearance: none;

      &::-ms-expand {
        display: none;
      }
    }
  }

  .new {
    color: color(orange);

    &::after {
      @include font('sans-serif', 'semibold');
      @include position(relative, -10px null null null);
      content: 'New';
      color: color(orange);
      font-size: rem(9px);
      text-transform: uppercase;
    }
  }

  .fltrow + tr {
    &:hover {
      background-color: transparent;
    }
  }
}

// Typeset
// -----------------------------------------------------------------------------
.typeset {
  * + * {
    margin-top: 30px;

    @media print {
      margin-top: 10px;
    }
  }

  * + ul,
  * + ol {
    margin-top: 50px;

    @media print {
      margin-top: 15px;
    }
  }

  div {
    @include font('sans-serif', 'light');

    strong,
    b {
      @include font('sans-serif', 'semibold');
    }

    em {
      @include font('sans-serif', 'light-italic');
    }

    a {
      @include font('sans-serif', 'semibold');
      color: color(cyan);
    }

    + p {
      margin-top: 30px;

      @media print {
        margin-top: 10px;
      }
    }
  }

  ol {
    counter-reset: ol;

    li {
      @include font('sans-serif', 'light');
      @include position(relative);
      @include margin(15px null);
      @include padding(null null null 32px);
      font-size: rem(16px);
      line-height: 1.375;
      counter-increment: ol;

      &::before {
        @include position(absolute, 4px null null 8px);
        content: counter(ol);
        font-size: rem(12px);
      }

      &::after {
        @include size(22px);
        @include position(absolute, 1px null null 0);
        content: '';
        border: 1px solid color(orange);
        border-radius: 50%;
      }
    }
  }

  ul {
    li {
      @include margin(15px null);
      @include font('sans-serif', 'light');
      @include position(relative);
      @include padding(null null null 32px);
      font-size: rem(16px);
      line-height: 1.375;

      &::before {
        @include position(absolute, 7px null null 0);
        @include size(10px);
        content: '';
        border: 1px solid color(orange);
        border-radius: 50%;
      }

      &::after {
        @include size(4px);
        @include position(absolute, 10px null null 3px);
        content: '';
        border: 1px solid color(orange);
        border-radius: 50%;
      }
    }
  }
}
