.content {
  + .content {
    margin-top: 75px;

    @media print {
      margin-top: 30px;
    }
  }

  &.content-text + .content-text {
    margin-top: 40px;
  }
}
