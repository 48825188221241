// Set up a decent box model on the root element
// -----------------------------------------------------------------------------
html {
  box-sizing: border-box;
  height: 100%;
}

// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}


// Set up body size to fill screen
// -----------------------------------------------------------------------------
body {
  @include position(relative);
  @include size(100%, auto);
}

// Set up site container to remove any overflow content
// -----------------------------------------------------------------------------
.site-container {
  @include margin(null null 150px null);
  overflow: hidden;

  @include mq($until: 'm') {
    margin-bottom: 80px;
  }
}

.print-logo {
  display: none;

  @media print {
    display: block;
    width: 202px;
  }
}

// Set up global SVG container
// -----------------------------------------------------------------------------
.svg {
  display: inline-block;

  svg {
    display: block;
  }
}

.container {
  @include size(100%, auto);
  @include margin(null auto);
  max-width: 1100px;

  @include mq($until: 'l') {
    max-width: 90%;
  }

  @include mq($until: 'm') {
    @include padding(null 20px);
    max-width: 100%;
  }
}

.container--small {
  max-width: 722px;
  background-color: color(white);
}

.orange {
  color: color(orange);
}

.blue {
  color: color(cyan);
}
