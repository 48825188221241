.key-value-list {
  a {
    @include font('sans-serif', 'semibold');
    color: color(cyan);

    p {
      @include font('sans-serif', 'semibold');
      color: color(cyan);
    }
  }
}

.key-value-element {
  + .key-value-element {
    margin-top: 5px;
  }

  dt,
  dd {
    display: inline-block;
  }

  dt {
    @include font('sans-serif', 'semibold');
    @include margin(null 4px null null);
    color: color(title);
  }

  dd {
    @include font('sans-serif', 'light');
  }
}


p + .key-value-list {
  margin-top: 20px;
}
