
.site-cookie {
  @include position(fixed, 100% null null 0);
  @include padding(40px null);
  @include size(100%, auto);
  z-index: layer(highest);
  transition: opacity .4s $ease-out-quart, transform .4s $ease-out-quart;
  border-top: 1px solid rgba(color(white), .1);
  background-color: $color-dark-green;

  &.is-visible {
    transform: translateY(-100%);
    opacity: 1;
  }

  .container {
    display: flex;
    align-items: center;

    @include mq($until: 'm') {
      flex-direction: column;
    }
  }

  .buttons-wrapper {
    display: flex;
    flex-shrink: 0;
    margin-left: 30px;

    @include mq($until: 'm') {
      @include margin(20px null null 0);
      width: 100%;
    }
  }

  .button {
    color: $color-white;
  }
}

.site-cookie-text {
  color: $color-white;

  @include mq($until: 'm') {
    width: 100%;
  }
}

.site-cookie-accept {
  margin-left: 20px;
}

.site-cookie-close {
  @include position(absolute, 0 0 null null);
  @include size(40px);

  &:hover {
    .svg {
      fill: $color-orange;
    }
  }

  .svg {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
    transition: fill .25s $ease-out-quad;
    fill: $color-white;
  }
}
