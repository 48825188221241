.content-specifications {
  h2 {
    @include margin(null null 20px null);
    color: color(orange);
    font-size: rem(32px);

    @media print {
      margin-bottom: 10px;
      font-size: rem(18px);
    }
  }

  .key-value-list {
    font-size: rem(16px);

    @include mq($from: 's') {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media print {
      font-size: rem(12px);
    }
  }

  .key-value-element {
    width: calc(50% - 10px);

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}
