[template="assay-list"] {
  .page-header {
    @include mq($from: 'l') {
      @include padding(210px null 130px null);
    }

    &::before {
      @include mq($from: 'l') {
        transform: translate(-50%, -50%) translateX(370px) translateY(250px);
      }
    }
  }
}
