.search-result-element {
  @include position(relative);
  @include margin(31px null 62px);
  max-width: 960px;

  @include mq($until: 'm') {
    @include margin(30px null);
  }

  .component-link {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: block;

    &:hover ~ .read-more {
      color: color(orange);

      .svg {
        fill: color(orange);
      }
    }
  }

  .headline {
    @include margin(9px null null null);
    @include font('sans-serif', 'semibold');
    color: color(dark-green);
    font-size: rem(10px);

    span + span,
    span + time {
      &::before {
        @include position(relative, -1px null null -1px);
        @include size(4px);
        @include margin(null 9px null 6px);
        content: '';
        display: inline-block;
        border: 1px solid color(dark-green);
        border-radius: 50%;
      }
    }
  }

  h3 {
    @include font('sans-serif', 'regular');
    color: color(title);
  }

  p {
    font-size: rem(16px);

    + p {
      margin-top: 22px;
    }
  }

  .read-more {
    @include margin(14px null null null);
    @include font('sans-serif', 'semibold');
    display: block;
    transition: color .25s $ease-out-quad;
    color: color(cyan);
  }

  .svg {
    @include position(relative, 2px null null null);
    @include margin(null 7px null null);
    transition: fill .25s $ease-out-quad;
    fill: color(cyan);
  }
}
