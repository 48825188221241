@charset 'UTF-8';

@mixin retina-image($filename, $background-size, $extension: png, $retina-filename: null, $retina-suffix: _2x) {
  background-image: image("#{$filename}.#{$extension}");

  @include hidpi {
    @if $retina-filename {
      background-image: image("#{$retina-filename}.#{$extension}");
    } @else {
      background-image: image("#{$filename}#{$retina-suffix}.#{$extension}");
    }

    background-size: $background-size;
  }
}
