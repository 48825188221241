[template="contact"] {
  .page-header {
    @include padding(180px null 280px null);
  }

  .contact-us-blocks {
    @include position(relative);
    margin-top: -200px;
  }

  .card {
    p {
      font-size: rem(16px);
    }
  }
}
