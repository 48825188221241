[template="assay-detail"] {
  .page-header {
    @include mq($from: 'm') {
      @include padding(180px null 370px null);
    }

    h1 {
      @include mq($from: 'm') {
        padding-right: 200px;
      }
    }
  }

  .content {
    margin-top: 0;

    .container {
      @include padding(80px null);

      @media print {
        @include padding(30px null);
      }

      @include mq($until: 'm') {
        @include padding(40px null);
      }
    }

    + .content {
      .container {
        border-top: 1px solid color(grey-border);
      }
    }
  }

  .article-start + .contents-wrapper .content:first-child .container {
    padding-top: 0;
  }

  .article {
    @include position(relative);
    @include margin(null auto);
    @include padding(60px null null null);
    width: 100%;
    max-width: 1100px;
    background-color: white;

    @include mq($from: 'm') {
      @include padding(120px null null null);
      margin-top: -282px;
    }

    @media print {
      @include padding(0 null);
      margin-top: 0;
    }
  }
}
