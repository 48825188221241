.card {
  background-color: color(white);
  box-shadow: 0 10px 20px 0 rgba(color(black), .1);

  .card-inner {
    @include position(relative);
    @include padding(60px 40px);
    @include size(100%, 100%);
    display: block;
    overflow: hidden;
    color: inherit;

    &:hover,
    &:focus {
      .read-more-icon::before {
        background-color: color(orange);
      }

      .read-more-icon .svg {
        fill: color(white);
      }
    }
  }

  .read-more-icon {
    @include size(11px);
    @include position(absolute, null 18px 16px null);

    &::before {
      @include position(absolute, -28px null null -32px);
      @include size(130px);
      content: '';
      transition: background-color .2s $ease-out-quad;
      border: 1px solid color(grey-border);
      border-radius: 50%;
    }

    .svg {
      @include position(relative);
      transition: fill .2s $ease-out-quad;
      fill: color(cyan);
    }
  }

  .svg {
    @include margin(null null 40px null);
    display: block;
    fill: color(orange);
  }

  .card-image {
    @include margin(35px null null null);
    display: block;
    width: 100%;
  }

  p {
    max-width: 360px;
    font-size: rem(16px);

    strong {
      @include font('sans-serif', 'regular');
      color: color(title);
    }
  }

  h2 {
    max-width: 320px;
    min-height: 84px;
    color: color(title);
    line-height: 1.05;

    @include mq($until: 'm') {
      min-height: 0;
      font-size: rem(40px);
    }

    + p {
      margin-top: 20px;
    }
  }
}
