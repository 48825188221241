@charset "UTF-8";
/**
 * Kennel 0.0.1
 *
 * @project : Kennel - Dogstudio Style Sheets
 * @author  : Dogstudio
 */
/**
 * Default colors palette
 *
 * @type Map
 */
/**
 * Default z-layers map
 *
 * @type Map
 */
.u-pull-left {
  float: left; }

.u-pull-right {
  float: right; }

.u-text-left {
  text-align: left; }

.u-text-right {
  text-align: right; }

.u-text-center {
  text-align: center; }

.u-text-justify {
  text-align: justify; }

.u-uppercase {
  text-transform: uppercase; }

.u-lowercase {
  text-transform: lowercase; }

.u-clearfix::after {
  clear: both;
  content: '';
  display: table; }

.u-inline {
  display: inline; }

.u-inline-block {
  display: inline-block; }

.u-block {
  display: block; }

.u-table {
  display: table; }

.u-cell {
  display: table-cell; }

.u-va-top {
  vertical-align: top; }

.u-va-middle {
  vertical-align: middle; }

.u-va-bottom {
  vertical-align: bottom; }

.u-hidden {
  display: none; }

.u-invisible {
  visibility: hidden; }

.u-hide-text {
  padding: 0;
  /* 1 */
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap; }

.u-visually-hidden, .ui-form .checkbox-wrapper input,
.wpcf7-form .checkbox-wrapper input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0; }
  .u-visually-hidden--focusable:active, .u-visually-hidden--focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto; }

.u-text-hyphenate {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  /* Browser Support */
  hyphens: auto; }

.u-button-reset {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent; }
  .u-button-reset:focus {
    outline: 0; }

.u-nobr {
  white-space: nowrap; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
  /* Browser Support */ }

table {
  border-spacing: 0;
  border-collapse: collapse; }

button {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  appearance: none; }

*:focus {
  outline: none; }

a,
.button,
button {
  transition: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  a .svg,
  .button .svg,
  button .svg {
    transition: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  a:focus,
  .button:focus,
  button:focus {
    color: #ff7549; }
    a:focus .svg,
    .button:focus .svg,
    button:focus .svg {
      fill: #ff7549; }

select::-ms-expand {
  display: none; }

a {
  text-decoration: none; }

html {
  box-sizing: border-box;
  height: 100%; }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  position: relative;
  height: auto;
  width: 100%; }

.site-container {
  margin-bottom: 150px;
  overflow: hidden; }
  @media (max-width: 47.99em) {
    .site-container {
      margin-bottom: 80px; } }

.print-logo {
  display: none; }
  @media print {
    .print-logo {
      display: block;
      width: 202px; } }

.svg {
  display: inline-block; }
  .svg svg {
    display: block; }

.container {
  height: auto;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px; }
  @media (max-width: 71.24em) {
    .container {
      max-width: 90%; } }
  @media (max-width: 47.99em) {
    .container {
      padding-right: 20px;
      padding-left: 20px;
      max-width: 100%; } }

.container--small {
  max-width: 722px;
  background-color: #fff; }

.orange {
  color: #ff7549; }

.blue {
  color: #7dc5b1; }

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* 1 */
  font-size: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

body {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  font-size: 1rem;
  line-height: 2; }
  @media print {
    body {
      font-size: 0.75rem; } }

.headline {
  display: block;
  font-size: 0.6875rem;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase; }
  .headline .svg {
    margin-left: 3px;
    fill: #ff7549; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: currentColor; }
  h1 strong,
  h1 b,
  h2 strong,
  h2 b,
  h3 strong,
  h3 b,
  h4 strong,
  h4 b,
  h5 strong,
  h5 b,
  h6 strong,
  h6 b {
    color: #ff7549; }

h1,
.h1 {
  font-size: 3.75rem;
  line-height: 1.25; }
  @media (max-width: 37.49em) {
    h1,
    .h1 {
      font-size: 2.8125rem; } }
  @media print {
    h1,
    .h1 {
      font-size: 2.25rem; } }

h2,
.h2 {
  font-size: 3.125rem;
  line-height: 1.3; }
  @media (max-width: 47.99em) {
    h2,
    .h2 {
      font-size: 2.5rem; } }
  @media print {
    h2,
    .h2 {
      font-size: 1.75rem; } }

h3,
.h3 {
  font-size: 2.5rem;
  line-height: 1.25; }
  @media (max-width: 47.99em) {
    h3,
    .h3 {
      font-size: 2rem; } }
  @media print {
    h3,
    .h3 {
      font-size: 1.25rem; } }

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.25; }
  @media print {
    h4,
    .h4 {
      font-size: 1rem; } }

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.1; }
  @media print {
    h5,
    .h5 {
      font-size: 0.875rem; } }

h6 .h6 {
  font-size: 1.125rem;
  line-height: 1.25; }
  @media print {
    h6 .h6 {
      font-size: 0.75rem; } }

h5,
h6,
.h5,
.h6 {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 400;
  font-style: normal; }

strong,
b {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal; }

em {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: italic; }

p {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal; }
  p strong,
  p b {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal; }
  p em {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 300;
    font-style: italic; }
  p a {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #7dc5b1; }
  p + p {
    margin-top: 30px; }
    @media print {
      p + p {
        margin-top: 10px; } }

sup {
  font-size: smaller;
  vertical-align: super; }

sub {
  font-size: smaller;
  vertical-align: sub; }

.lead {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #373739;
  font-size: 1.125rem; }
  .lead p {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #373739;
    font-size: 1.125rem; }

@media (max-width: 47.99em) {
  .table-wrapper {
    width: 100%;
    height: auto;
    overflow: auto; }
    .table-wrapper table {
      min-width: 760px; } }

table {
  color: #373739;
  font-size: 0.875rem; }
  table td,
  table th {
    padding-right: 5px;
    text-align: left; }
  table th {
    padding-bottom: 24px;
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 0.875rem; }
  table td {
    padding-top: 13px;
    padding-bottom: 13px; }
  table tr {
    border-bottom: 1px solid #e1e1e1; }
    table tr:hover {
      background-color: rgba(125, 197, 177, 0.05); }
  table a {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #7dc5b1; }

table.TF thead tr:not(.fltrow) th {
  backface-visibility: hidden;
  line-height: 1;
  vertical-align: bottom;
  pointer-events: none; }

table.TF .fltrow {
  background-color: #eee; }
  table.TF .fltrow td {
    position: relative;
    padding: 11px 0; }
  table.TF .fltrow select {
    padding: 8px 24px 8px 0;
    font-family: "SourceSansPro", sans-serif;
    font-weight: 400;
    font-style: normal;
    height: auto;
    width: 100%;
    margin-bottom: 2px;
    backface-visibility: hidden;
    border: 0;
    border-radius: 0;
    background: transparent;
    color: #373739;
    font-size: 0.875rem;
    line-height: 1;
    appearance: none; }
    table.TF .fltrow select::-ms-expand {
      display: none; }

table.TF .new {
  color: #ff7549; }
  table.TF .new::after {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    position: relative;
    top: -10px;
    content: 'New';
    color: #ff7549;
    font-size: 0.5625rem;
    text-transform: uppercase; }

table.TF .fltrow + tr:hover {
  background-color: transparent; }

.typeset * + * {
  margin-top: 30px; }
  @media print {
    .typeset * + * {
      margin-top: 10px; } }

.typeset * + ul,
.typeset * + ol {
  margin-top: 50px; }
  @media print {
    .typeset * + ul,
    .typeset * + ol {
      margin-top: 15px; } }

.typeset div {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal; }
  .typeset div strong,
  .typeset div b {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal; }
  .typeset div em {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 300;
    font-style: italic; }
  .typeset div a {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #7dc5b1; }
  .typeset div + p {
    margin-top: 30px; }
    @media print {
      .typeset div + p {
        margin-top: 10px; } }

.typeset ol {
  counter-reset: ol; }
  .typeset ol li {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 300;
    font-style: normal;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 32px;
    font-size: 1rem;
    line-height: 1.375;
    counter-increment: ol; }
    .typeset ol li::before {
      position: absolute;
      top: 4px;
      left: 8px;
      content: counter(ol);
      font-size: 0.75rem; }
    .typeset ol li::after {
      height: 22px;
      width: 22px;
      position: absolute;
      top: 1px;
      left: 0;
      content: '';
      border: 1px solid #ff7549;
      border-radius: 50%; }

.typeset ul li {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal;
  position: relative;
  padding-left: 32px;
  font-size: 1rem;
  line-height: 1.375; }
  .typeset ul li::before {
    position: absolute;
    top: 7px;
    left: 0;
    height: 10px;
    width: 10px;
    content: '';
    border: 1px solid #ff7549;
    border-radius: 50%; }
  .typeset ul li::after {
    height: 4px;
    width: 4px;
    position: absolute;
    top: 10px;
    left: 3px;
    content: '';
    border: 1px solid #ff7549;
    border-radius: 50%; }

.button {
  padding: 14px 34px;
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: inline-block;
  transition: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border: 1px solid #7dc5b1;
  border-radius: 5px;
  color: currentColor;
  font-size: 0.6875rem;
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none; }
  @media (max-width: 37.49em) {
    .button {
      padding-right: 21px;
      padding-left: 21px; } }
  .button:hover {
    border-color: #ff7549;
    background-color: #ff7549;
    color: #fff; }
  .button:focus {
    border-color: #ff7549;
    color: #ff7549; }
  .button:focus:hover {
    color: white; }

.button--green {
  background-color: #054f59; }

.pagination {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  letter-spacing: 2px;
  text-transform: uppercase; }
  .pagination .svg {
    fill: #7dc5b1; }
  .pagination > a {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    flex-shrink: 0;
    color: #054f59; }
    .pagination > a + a {
      margin-left: 75px; }
      @media (max-width: 47.99em) {
        .pagination > a + a {
          margin-left: 0; } }
    .pagination > a .svg {
      transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .pagination .pagination-next:hover .svg {
    transform: translateX(8px); }
  .pagination .pagination-prev:hover .svg {
    transform: translateX(-8px); }
  @media (max-width: 47.99em) {
    .pagination .first,
    .pagination .last {
      display: none; } }

.pagination-list {
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pagination-list a,
  .pagination-list span {
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
    display: block;
    color: #222; }
    @media (max-width: 47.99em) {
      .pagination-list a,
      .pagination-list span {
        padding-right: 5px;
        padding-left: 5px; } }
  .pagination-list a::before,
  .pagination-list .current::before {
    position: absolute;
    bottom: 0;
    left: calc(50% - 1px);
    height: 2px;
    width: calc(100% - 20px);
    content: '';
    transform: translateX(-50%);
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 0;
    background-color: #7dc5b1; }
    @media (max-width: 47.99em) {
      .pagination-list a::before,
      .pagination-list .current::before {
        width: calc(100% - 10px); } }
  .pagination-list a:hover::before {
    opacity: 1; }
  .pagination-list .current {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal; }
    .pagination-list .current::before {
      opacity: 1; }

.custom-select {
  position: relative; }
  .custom-select::before {
    position: absolute;
    top: 25px;
    right: 26px;
    content: '';
    transform: translateY(-50%);
    border-width: 5px 4px 0;
    border-style: solid;
    border-color: #000 transparent transparent;
    pointer-events: none; }

.custom-select-container.is-disabled {
  opacity: .35; }

.js-custom-select,
.custom-select-opener {
  padding: 17px 45px 17px 20px;
  height: 50px;
  width: 100%;
  display: block;
  overflow: hidden;
  border: 1px solid #000;
  border-radius: 0;
  outline: none;
  background: transparent;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  appearance: none; }

.js-custom-select {
  padding-top: 15px;
  padding-bottom: 15px; }

.custom-select-container select {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  padding: 0;
  visibility: hidden;
  border: 0 none; }

.custom-select-panel {
  position: absolute;
  top: 100%;
  height: auto;
  width: 100%;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: overflow-y .1s .5s;
  background-color: #fff; }

.custom-select-container.is-open .custom-select-panel {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e1e1e1; }

.custom-select-option {
  padding: 8px 20px;
  background-color: #fff;
  color: #000;
  cursor: pointer; }
  .custom-select-option + .custom-select-option {
    border-top: 1px solid #eee; }

.custom-select-option.is-selected {
  background-color: rgba(125, 197, 177, 0.05);
  color: #000; }
  .custom-select-option.is-selected + .custom-select-option {
    border-color: #ddd; }

.custom-select-option.has-focus {
  background-color: rgba(125, 197, 177, 0.05);
  color: #000; }
  .custom-select-option.has-focus + .custom-select-option {
    border-color: #ddd; }

.custom-select-optgroup .custom-select-option {
  padding-left: 40px; }

.custom-select-optgroup::before {
  height: auto;
  width: 100%;
  padding: 10px 20px;
  content: attr(data-label);
  display: block;
  border-bottom: 1px solid #ddd;
  background-color: #eee;
  color: #000;
  font-weight: 700; }

.key-value-list a {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #7dc5b1; }
  .key-value-list a p {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #7dc5b1; }

.key-value-element + .key-value-element {
  margin-top: 5px; }

.key-value-element dt,
.key-value-element dd {
  display: inline-block; }

.key-value-element dt {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-right: 4px;
  color: #373739; }

.key-value-element dd {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal; }

p + .key-value-list {
  margin-top: 20px; }

.card {
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
  .card .card-inner {
    position: relative;
    padding: 60px 40px;
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    color: inherit; }
    .card .card-inner:hover .read-more-icon::before, .card .card-inner:focus .read-more-icon::before {
      background-color: #ff7549; }
    .card .card-inner:hover .read-more-icon .svg, .card .card-inner:focus .read-more-icon .svg {
      fill: #fff; }
  .card .read-more-icon {
    height: 11px;
    width: 11px;
    position: absolute;
    right: 18px;
    bottom: 16px; }
    .card .read-more-icon::before {
      position: absolute;
      top: -28px;
      left: -32px;
      height: 130px;
      width: 130px;
      content: '';
      transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      border: 1px solid #e1e1e1;
      border-radius: 50%; }
    .card .read-more-icon .svg {
      position: relative;
      transition: fill 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      fill: #7dc5b1; }
  .card .svg {
    margin-bottom: 40px;
    display: block;
    fill: #ff7549; }
  .card .card-image {
    margin-top: 35px;
    display: block;
    width: 100%; }
  .card p {
    max-width: 360px;
    font-size: 1rem; }
    .card p strong {
      font-family: "SourceSansPro", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: #373739; }
  .card h2 {
    max-width: 320px;
    min-height: 84px;
    color: #373739;
    line-height: 1.05; }
    @media (max-width: 47.99em) {
      .card h2 {
        min-height: 0;
        font-size: 2.5rem; } }
    .card h2 + p {
      margin-top: 20px; }

.news-card {
  width: 100%;
  max-width: 284px; }
  @media (max-width: 47.99em) {
    .news-card {
      flex-shrink: 0; } }
  @media (max-width: 37.49em) {
    .news-card {
      max-width: 360px; } }
  .news-card a {
    display: block;
    width: 100%; }
    .news-card a:hover h3, .news-card a:focus h3 {
      color: #ff7549; }
  .news-card figure {
    position: relative;
    margin-bottom: 28px;
    width: 100%;
    background-color: #054f59; }
    .news-card figure:before {
      padding-top: 105.633802817%;
      display: block;
      content: ''; }
    .news-card figure img {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      display: block;
      transform: translate(-50%, -50%); }
      .object-fit .news-card figure img {
        object-fit: cover;
        object-position: center; }
      .no-object-fit .news-card figure img {
        font-family: "object-fit: cover; object-position: center";
        object-position: center; }
  .news-card .headline {
    margin-bottom: 10px;
    color: #ff7549;
    font-size: 0.625rem; }
  .news-card h3 {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: #000;
    line-height: 1.6; }
  .news-card p {
    margin-top: 10px;
    max-width: 240px;
    color: #000;
    font-size: 1rem;
    line-height: 1.87; }

.ui-form .form-element,
.wpcf7-form .form-element {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%; }
  @media (max-width: 37.49em) {
    .ui-form .form-element + .form-element,
    .wpcf7-form .form-element + .form-element {
      margin-top: 30px; } }
  .ui-form .form-element--error label,
  .wpcf7-form .form-element--error label {
    color: red; }

.ui-form .form-row + .form-row,
.wpcf7-form .form-row + .form-row {
  margin-top: 45px; }
  @media (max-width: 37.49em) {
    .ui-form .form-row + .form-row,
    .wpcf7-form .form-row + .form-row {
      margin-top: 30px; } }

.ui-form .form-row:not(.form-row--double) .form-element + .form-element,
.wpcf7-form .form-row:not(.form-row--double) .form-element + .form-element {
  margin-top: 45px; }

@media (min-width: 37.5em) {
  .ui-form .form-row--double,
  .wpcf7-form .form-row--double {
    display: flex;
    justify-content: space-between; }
    .ui-form .form-row--double .form-element,
    .wpcf7-form .form-row--double .form-element {
      max-width: calc(50% - 16px); } }

.ui-form .required::after,
.wpcf7-form .required::after {
  content: ' *'; }

.ui-form label,
.wpcf7-form label {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 8px;
  display: block;
  color: #575757;
  font-size: 0.875rem;
  line-height: 1.25; }
  .ui-form label a,
  .wpcf7-form label a {
    text-decoration: underline; }
  .ui-form label small,
  .wpcf7-form label small {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;
    color: #8e8e8e;
    font-size: 0.6875rem; }

.ui-form input[type="text"],
.ui-form input[type="email"],
.ui-form input[type="tel"],
.ui-form input[type="date"],
.ui-form input[type="number"],
.ui-form textarea,
.ui-form select,
.wpcf7-form input[type="text"],
.wpcf7-form input[type="email"],
.wpcf7-form input[type="tel"],
.wpcf7-form input[type="date"],
.wpcf7-form input[type="number"],
.wpcf7-form textarea,
.wpcf7-form select {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 16px 20px;
  height: auto;
  width: 100%;
  min-height: 38px;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background: transparent;
  color: #000;
  font-size: 0.875rem;
  line-height: 1;
  appearance: none; }
  .ui-form input[type="text"]::placeholder,
  .ui-form input[type="email"]::placeholder,
  .ui-form input[type="tel"]::placeholder,
  .ui-form input[type="date"]::placeholder,
  .ui-form input[type="number"]::placeholder,
  .ui-form textarea::placeholder,
  .ui-form select::placeholder,
  .wpcf7-form input[type="text"]::placeholder,
  .wpcf7-form input[type="email"]::placeholder,
  .wpcf7-form input[type="tel"]::placeholder,
  .wpcf7-form input[type="date"]::placeholder,
  .wpcf7-form input[type="number"]::placeholder,
  .wpcf7-form textarea::placeholder,
  .wpcf7-form select::placeholder {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: rgba(34, 34, 34, 0.7);
    font-size: 0.875rem; }
  .ui-form input[type="text"]:focus::placeholder,
  .ui-form input[type="email"]:focus::placeholder,
  .ui-form input[type="tel"]:focus::placeholder,
  .ui-form input[type="date"]:focus::placeholder,
  .ui-form input[type="number"]:focus::placeholder,
  .ui-form textarea:focus::placeholder,
  .ui-form select:focus::placeholder,
  .wpcf7-form input[type="text"]:focus::placeholder,
  .wpcf7-form input[type="email"]:focus::placeholder,
  .wpcf7-form input[type="tel"]:focus::placeholder,
  .wpcf7-form input[type="date"]:focus::placeholder,
  .wpcf7-form input[type="number"]:focus::placeholder,
  .wpcf7-form textarea:focus::placeholder,
  .wpcf7-form select:focus::placeholder {
    color: rgba(34, 34, 34, 0.35); }
  .ui-form input[type="text"].wpcf7-not-valid,
  .ui-form input[type="email"].wpcf7-not-valid,
  .ui-form input[type="tel"].wpcf7-not-valid,
  .ui-form input[type="date"].wpcf7-not-valid,
  .ui-form input[type="number"].wpcf7-not-valid,
  .ui-form textarea.wpcf7-not-valid,
  .ui-form select.wpcf7-not-valid,
  .wpcf7-form input[type="text"].wpcf7-not-valid,
  .wpcf7-form input[type="email"].wpcf7-not-valid,
  .wpcf7-form input[type="tel"].wpcf7-not-valid,
  .wpcf7-form input[type="date"].wpcf7-not-valid,
  .wpcf7-form input[type="number"].wpcf7-not-valid,
  .wpcf7-form textarea.wpcf7-not-valid,
  .wpcf7-form select.wpcf7-not-valid {
    border-color: red; }

.ui-form select,
.wpcf7-form select {
  line-height: 1.2; }

.ui-form textarea,
.wpcf7-form textarea {
  min-height: 100px;
  resize: vertical; }

.ui-form .wpcf7-form-control-wrap,
.wpcf7-form .wpcf7-form-control-wrap {
  position: relative;
  display: block; }
  .ui-form .wpcf7-form-control-wrap [role="alert"],
  .wpcf7-form .wpcf7-form-control-wrap [role="alert"] {
    position: absolute;
    top: 100%;
    left: 0;
    height: auto;
    width: 100%;
    font-size: 0.75rem; }

.ui-form .phone-wrapper,
.wpcf7-form .phone-wrapper {
  position: relative; }
  .ui-form .phone-wrapper::before,
  .wpcf7-form .phone-wrapper::before {
    position: absolute;
    top: 50%;
    left: 0;
    height: 26px;
    width: 50px;
    content: '';
    transform: translateY(-50%);
    border-right: 1px solid #e1e1e1;
    background-image: url("../assets/images/icons/phone.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 8px;
    pointer-events: none; }
  .ui-form .phone-wrapper input,
  .wpcf7-form .phone-wrapper input {
    padding-left: 66px; }

.ui-form .select-wrapper,
.wpcf7-form .select-wrapper {
  position: relative; }
  .ui-form .select-wrapper select,
  .wpcf7-form .select-wrapper select {
    padding-right: 30px;
    height: 52px; }
  .ui-form .select-wrapper .is-disabled,
  .wpcf7-form .select-wrapper .is-disabled {
    opacity: .5;
    cursor: not-allowed; }
  .ui-form .select-wrapper::before,
  .wpcf7-form .select-wrapper::before {
    height: 6px;
    width: 11px;
    position: absolute;
    top: 50%;
    right: 20px;
    content: '';
    background-image: url("../assets/images/icons/arrow-down.png");
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none; }

.ui-form .checkbox-wrapper,
.wpcf7-form .checkbox-wrapper {
  position: relative; }
  @media (max-width: 37.49em) {
    .ui-form .checkbox-wrapper + .checkbox-wrapper,
    .wpcf7-form .checkbox-wrapper + .checkbox-wrapper {
      margin-top: 20px; } }
  .ui-form .checkbox-wrapper input:focus + label,
  .wpcf7-form .checkbox-wrapper input:focus + label {
    color: #ff7549; }
  .ui-form .checkbox-wrapper label,
  .wpcf7-form .checkbox-wrapper label {
    position: relative;
    padding: 4px 0 4px 33px;
    margin-bottom: 0;
    cursor: pointer; }
    .ui-form .checkbox-wrapper label::before,
    .wpcf7-form .checkbox-wrapper label::before {
      position: absolute;
      top: 1px;
      left: 0;
      height: 24px;
      width: 24px;
      content: '';
      border: 1px solid #e1e1e1; }
    .ui-form .checkbox-wrapper label::after,
    .wpcf7-form .checkbox-wrapper label::after {
      position: absolute;
      top: 5px;
      left: 9px;
      height: 12px;
      width: 6px;
      content: '';
      transform: rotate(45deg);
      transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      opacity: 0; }
    .ui-form .checkbox-wrapper label:hover,
    .wpcf7-form .checkbox-wrapper label:hover {
      color: #ff7549; }
  .ui-form .checkbox-wrapper input:checked + label::before,
  .wpcf7-form .checkbox-wrapper input:checked + label::before {
    border-color: #ff7549;
    background-color: #ff7549; }
  .ui-form .checkbox-wrapper input:checked + label::after,
  .wpcf7-form .checkbox-wrapper input:checked + label::after {
    opacity: 1; }

.ui-form .radio-wrapper input,
.wpcf7-form .radio-wrapper input {
  display: none; }

.ui-form .radio-wrapper label,
.wpcf7-form .radio-wrapper label {
  position: relative;
  padding: 4px 0 4px 36px;
  margin-bottom: 0;
  cursor: pointer; }
  .ui-form .radio-wrapper label::before,
  .wpcf7-form .radio-wrapper label::before {
    position: absolute;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    content: '';
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%; }
  .ui-form .radio-wrapper label::after,
  .wpcf7-form .radio-wrapper label::after {
    position: absolute;
    top: 7px;
    left: 4px;
    height: 10px;
    width: 10px;
    content: '';
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 100%;
    opacity: 0;
    background-color: #000; }
  .ui-form .radio-wrapper label:hover,
  .wpcf7-form .radio-wrapper label:hover {
    color: #00f; }

.ui-form .radio-wrapper input:checked + label::before,
.wpcf7-form .radio-wrapper input:checked + label::before {
  border: 1px solid #000; }

.ui-form .radio-wrapper input:checked + label::after,
.wpcf7-form .radio-wrapper input:checked + label::after {
  opacity: 1; }

.ui-form .form-footer,
.wpcf7-form .form-footer {
  margin-top: 40px;
  text-align: center; }

.ui-form--smaller .form-row + .form-row {
  margin-top: 30px; }

.ui-form--smaller .form-row:not(.form-row--double) .form-element + .form-element {
  margin-top: 30px; }

.ui-form--smaller .checkbox-wrapper label,
.ui-form--smaller .radio-wrapper label {
  padding-top: 6px;
  font-size: 0.875rem; }

.form-feedback,
.wpcf7-response-output {
  margin-top: 20px; }
  .form-feedback--success,
  .wpcf7-response-output--success {
    color: green; }
  .form-feedback--error,
  .wpcf7-response-output--error {
    color: red; }

.search-result-element {
  position: relative;
  margin-top: 31px;
  margin-bottom: 62px;
  max-width: 960px; }
  @media (max-width: 47.99em) {
    .search-result-element {
      margin-top: 30px;
      margin-bottom: 30px; } }
  .search-result-element .component-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block; }
    .search-result-element .component-link:hover ~ .read-more {
      color: #ff7549; }
      .search-result-element .component-link:hover ~ .read-more .svg {
        fill: #ff7549; }
  .search-result-element .headline {
    margin-top: 9px;
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #054f59;
    font-size: 0.625rem; }
    .search-result-element .headline span + span::before,
    .search-result-element .headline span + time::before {
      position: relative;
      top: -1px;
      left: -1px;
      height: 4px;
      width: 4px;
      margin-right: 9px;
      margin-left: 6px;
      content: '';
      display: inline-block;
      border: 1px solid #054f59;
      border-radius: 50%; }
  .search-result-element h3 {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #373739; }
  .search-result-element p {
    font-size: 1rem; }
    .search-result-element p + p {
      margin-top: 22px; }
  .search-result-element .read-more {
    margin-top: 14px;
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    display: block;
    transition: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: #7dc5b1; }
  .search-result-element .svg {
    position: relative;
    top: 2px;
    margin-right: 7px;
    transition: fill 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    fill: #7dc5b1; }

.site-header {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 60;
  color: #fff; }
  @media print {
    .site-header {
      display: none; } }
  .site-header > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 97px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    @media (max-width: 47.99em) {
      .site-header > .container {
        height: 73px; } }
  .site-header.site-header--light {
    color: #222; }
  .site-header .site-footer-nav-links {
    display: none; }
    @media (max-width: 71.24em) {
      .site-header .site-footer-nav-links {
        display: flex;
        flex-wrap: wrap; } }
    .site-header .site-footer-nav-links a {
      font-family: "SourceSansPro", sans-serif;
      font-weight: 300;
      font-style: normal;
      color: #fff; }

.site-header-logo {
  display: block;
  width: 100%;
  max-width: 202px; }
  @media (max-width: 47.99em) {
    .site-header-logo {
      max-width: 135px; } }
  .site-header-logo img {
    display: block;
    width: 100%; }

.site-header-nav-close {
  position: fixed;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px; }
  @media (min-width: 71.25em) {
    .site-header-nav-close {
      display: none; } }

@media (max-width: 71.24em) {
  .site-header-nav {
    position: fixed;
    top: 0;
    left: 0;
    padding: 80px 50px;
    height: 100%;
    width: 100%;
    display: flex;
    visibility: hidden;
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 0;
    background: #054f59; } }

.site-header-nav .button {
  margin-top: 10px;
  margin-bottom: auto;
  color: #fff;
  text-align: center; }
  @media (min-width: 37.5em) {
    .site-header-nav .button {
      align-self: flex-start; } }
  @media (min-width: 71.25em) {
    .site-header-nav .button {
      display: none; } }

@media (min-width: 71.25em) {
  .site-header-nav .mobile-dropdown-toggler {
    margin-left: -17px;
    pointer-events: none; } }

@media (max-width: 71.24em) {
  .site-header-nav .mobile-dropdown-toggler {
    position: relative;
    height: 33px;
    width: 33px;
    z-index: 2;
    margin-left: 8px;
    border-radius: 50%;
    background-color: #7dc5b1; }
    .site-header-nav .mobile-dropdown-toggler .svg {
      margin-left: 0;
      fill: #fff; } }

@media (min-width: 71.25em) {
  .site-header-nav > ul {
    display: flex;
    align-items: center; } }

.site-header-nav > ul:not(.site-footer-nav-links) > li > a:not(.button) {
  display: inline-block;
  transition: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: currentColor;
  font-size: 0.875rem; }
  @media (max-width: 71.24em) {
    .site-header-nav > ul:not(.site-footer-nav-links) > li > a:not(.button) {
      color: #fff;
      font-size: 1.5rem; } }
  .site-header-nav > ul:not(.site-footer-nav-links) > li > a:not(.button):hover, .site-header-nav > ul:not(.site-footer-nav-links) > li > a:not(.button):focus {
    color: #ff7549; }
    @media (min-width: 71.25em) {
      .site-header-nav > ul:not(.site-footer-nav-links) > li > a:not(.button):hover + button .svg, .site-header-nav > ul:not(.site-footer-nav-links) > li > a:not(.button):focus + button .svg {
        fill: #ff7549; } }

.site-header-nav .item {
  position: relative; }
  .site-header-nav .item > a {
    padding: 7px 0; }
    @media (min-width: 71.25em) {
      .site-header-nav .item > a {
        padding: 11px 15px;
        text-transform: uppercase; } }
  @media (min-width: 71.25em) {
    .site-header-nav .item:hover .sub-items-list {
      visibility: visible;
      opacity: 1; } }

.site-header-nav .svg {
  margin-left: 4px;
  transition: fill 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  fill: #7dc5b1; }

.site-header-nav .sub-items-list {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 32px 40px;
  visibility: hidden;
  max-width: 220px;
  transform: translateX(-50%);
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: 2px;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
  color: #222;
  white-space: nowrap; }
  @media (max-width: 71.24em) {
    .site-header-nav .sub-items-list {
      padding: 15px 50px;
      margin-right: -50px;
      margin-left: -50px;
      display: none;
      max-width: none;
      border-radius: 0;
      box-shadow: none; }
      .site-header-nav .sub-items-list.is-open {
        position: relative;
        top: auto;
        left: auto;
        display: block;
        visibility: visible;
        transform: none;
        opacity: 1; } }
  @media (max-width: 71.24em) {
    .site-header-nav .sub-items-list {
      font-size: 0.875rem; } }
  .site-header-nav .sub-items-list::before {
    position: absolute;
    top: -3px;
    left: 50%;
    height: 8px;
    width: 8px;
    content: '';
    transform: translateX(-50%) rotate(45deg);
    background-color: #fff; }
    @media (max-width: 71.24em) {
      .site-header-nav .sub-items-list::before {
        left: 52px;
        transform: rotate(45deg); } }
  .site-header-nav .sub-items-list a {
    padding-top: 9px;
    padding-bottom: 9px;
    display: block;
    transition: color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: currentColor;
    font-size: 0.875rem;
    line-height: 1.52; }
    .site-header-nav .sub-items-list a:hover {
      color: #ff7549; }

.site-header-extras .button {
  padding: 12px 26px; }
  @media (max-width: 71.24em) {
    .site-header-extras .button {
      display: none; } }

.site-menu-toggler {
  height: 37px;
  width: 37px;
  color: currentColor; }
  @media (min-width: 71.25em) {
    .site-menu-toggler {
      display: none; } }
  .site-menu-toggler .svg {
    position: relative;
    top: 3px;
    fill: currentColor; }

.site-header-search-button {
  height: 37px;
  width: 37px;
  margin-right: -10px;
  margin-left: 6px;
  color: currentColor; }
  .site-header-search-button .svg {
    position: relative;
    top: 3px;
    fill: currentColor; }
  .site-header-search-button:focus {
    color: #ff7549; }

.site-header-search {
  position: absolute;
  top: 0;
  left: 0;
  height: 206px;
  width: 100%;
  display: flex;
  visibility: hidden;
  align-items: center;
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 71.24em) {
    .site-header-search {
      height: 140px; } }
  @media (max-width: 71.24em) {
    .site-header-search .container {
      margin-top: 30px; } }

.search-input-wrapper {
  display: flex;
  border: 1px solid #e1e1e1;
  border-radius: 4px; }
  .search-input-wrapper form {
    width: 100%; }
  .search-input-wrapper input {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 300;
    font-style: normal;
    padding: 27px 60px;
    width: 100%;
    border: 0;
    border-radius: 0;
    font-size: 1.625rem; }
    @media (max-width: 71.24em) {
      .search-input-wrapper input {
        padding: 12px 20px;
        font-size: 1.25rem; } }
    @media (max-width: 47.99em) {
      .search-input-wrapper input {
        font-size: 1rem; } }
    .search-input-wrapper input::placeholder {
      color: rgba(34, 34, 34, 0.7); }
  .search-input-wrapper .search-submit {
    position: relative;
    padding-right: 26px;
    height: auto;
    width: 130px; }
    @media (max-width: 71.24em) {
      .search-input-wrapper .search-submit {
        width: 80px;
        padding-right: 0; } }
    @media (max-width: 47.99em) {
      .search-input-wrapper .search-submit {
        width: 60px; } }
    .search-input-wrapper .search-submit::before {
      position: absolute;
      top: calc(50% - 20px);
      left: 0;
      height: 40px;
      width: 1px;
      content: '';
      background-color: #e1e1e1; }
    .search-input-wrapper .search-submit .svg {
      transition: fill 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      fill: #7dc5b1; }
    .search-input-wrapper .search-submit:hover .svg, .search-input-wrapper .search-submit:focus .svg {
      fill: #ff7549; }

.site-header-search-close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 40px; }
  .site-header-search-close .svg {
    transition: fill 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    fill: #7dc5b1; }
  .site-header-search-close:hover .svg, .site-header-search-close:focus .svg {
    fill: #ff7549; }

.search-is-open .site-header-search {
  visibility: visible;
  opacity: 1; }

.menu-is-open .site-header-nav {
  visibility: visible;
  opacity: 1; }

.site-header--light {
  background-color: #fff; }
  .site-header--light > .container {
    border-bottom-color: #efefef; }

.site-footer {
  display: flex;
  align-items: center;
  border-top: 1px solid #f4f4f4; }
  @media print {
    .site-footer {
      display: none; } }
  @media (min-width: 48em) {
    .site-footer {
      height: 160px; } }
  @media (max-width: 47.99em) {
    .site-footer {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media (min-width: 48em) {
    .site-footer .container {
      display: flex;
      align-items: center;
      justify-content: space-between; } }

.site-footer-nav {
  width: 100%; }
  @media (min-width: 37.5em) {
    .site-footer-nav {
      display: flex;
      align-items: center; } }
  .site-footer-nav ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }

@media (max-width: 37.49em) {
  .site-footer-nav-links {
    margin-top: 20px; } }

.site-footer-nav-links li {
  position: relative;
  color: #9d9d9d;
  font-size: 0.75rem; }
  @media (max-width: 37.49em) {
    .site-footer-nav-links li:first-child {
      margin-left: -13px; } }
  @media (min-width: 37.5em) {
    .site-footer-nav-links li.copyright {
      margin-left: 10px; } }
  @media (max-width: 37.49em) {
    .site-footer-nav-links li.copyright {
      display: block;
      width: 100%;
      margin-top: 12px; } }
  .site-footer-nav-links li + li:not(.copyright)::before {
    position: absolute;
    top: calc(50% - 2px);
    left: -2px;
    height: 4px;
    width: 4px;
    content: '';
    border: 1px solid #ff7549;
    border-radius: 50%; }
  .site-footer-nav-links li a {
    padding-right: 13px;
    padding-left: 13px;
    display: inline-block;
    transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: #000;
    font-size: 0.875rem; }
    .site-footer-nav-links li a:hover, .site-footer-nav-links li a:focus {
      color: #ff7549; }

.site-footer-nav-socials {
  flex-shrink: 0; }
  @media (min-width: 37.5em) {
    .site-footer-nav-socials {
      margin-left: 25px; } }
  @media (max-width: 37.49em) {
    .site-footer-nav-socials {
      margin-top: 45px; } }
  @media (max-width: 37.49em) {
    .site-footer-nav-socials li:first-child {
      margin-left: -13px; } }
  .site-footer-nav-socials a {
    padding-right: 13px;
    padding-left: 13px;
    display: inline-block; }
    .site-footer-nav-socials a:hover .svg, .site-footer-nav-socials a:focus .svg {
      fill: #ff7549; }
  .site-footer-nav-socials .svg {
    transition: fill 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.site-footer-logo {
  margin-right: 30px;
  display: block;
  width: 51px; }
  @media (max-width: 37.49em) {
    .site-footer-logo {
      width: 44px; } }
  .site-footer-logo img {
    display: block;
    width: 100%; }

.feder-logo {
  display: block;
  max-width: 228px; }
  @media (min-width: 48em) {
    .feder-logo {
      margin-left: 20px; } }
  @media (max-width: 47.99em) {
    .feder-logo {
      margin-top: 30px; } }
  @media (min-width: 37.5em) and (max-width: 47.99em) {
    .feder-logo {
      margin-left: auto; } }

.site-cookie {
  position: fixed;
  top: 100%;
  left: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  height: auto;
  width: 100%;
  z-index: 60;
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #054f59; }
  .site-cookie.is-visible {
    transform: translateY(-100%);
    opacity: 1; }
  .site-cookie .container {
    display: flex;
    align-items: center; }
    @media (max-width: 47.99em) {
      .site-cookie .container {
        flex-direction: column; } }
  .site-cookie .buttons-wrapper {
    display: flex;
    flex-shrink: 0;
    margin-left: 30px; }
    @media (max-width: 47.99em) {
      .site-cookie .buttons-wrapper {
        margin-top: 20px;
        margin-left: 0;
        width: 100%; } }
  .site-cookie .button {
    color: #fff; }

.site-cookie-text {
  color: #fff; }
  @media (max-width: 47.99em) {
    .site-cookie-text {
      width: 100%; } }

.site-cookie-accept {
  margin-left: 20px; }

.site-cookie-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px; }
  .site-cookie-close:hover .svg {
    fill: #ff7549; }
  .site-cookie-close .svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: fill 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    fill: #fff; }

@media (min-width: 48em) {
  .content-text .container {
    display: flex;
    justify-content: space-between; } }

.content-text .content-text-wrapper {
  width: 100%; }

.content-text figure {
  flex-shrink: 0;
  width: 100%; }
  @media (max-width: 47.99em) {
    .content-text figure {
      margin-top: 40px; } }
  @media (min-width: 48em) {
    .content-text figure {
      max-width: 282px; } }
  .content-text figure img {
    display: block;
    width: 100%; }

.content-text p {
  margin-top: 0; }
  .content-text p + p {
    margin-top: 0; }

.content-text h2 + p,
.content-text h3 + p,
.content-text h4 + p,
.content-text h5 + p,
.content-text h6 + p {
  margin-top: 30px; }
  @media print {
    .content-text h2 + p,
    .content-text h3 + p,
    .content-text h4 + p,
    .content-text h5 + p,
    .content-text h6 + p {
      margin-top: 10px; } }

@media (min-width: 48em) {
  .content-text--right figure {
    margin-left: 60px; } }

.content-text--left .container {
  flex-direction: row-reverse; }

@media (min-width: 48em) {
  .content-text--left figure {
    margin-right: 60px; } }

.content-quote .svg-quote {
  position: relative;
  margin-bottom: 30px;
  margin-left: 10px;
  fill: #ff7549; }
  .content-quote .svg-quote::before {
    height: 34px;
    width: 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 117, 73, 0.5);
    border-radius: 50%; }

.content-quote blockquote {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 1.25; }

.content-quote cite {
  margin-top: 20px;
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal;
  display: block;
  font-size: 0.875rem; }

.content-image figure {
  display: block;
  width: 100%; }

.content-image img {
  display: block;
  width: 100%; }

.content-image figcaption {
  margin-top: 22px;
  font-size: 0.75rem; }
  .content-image figcaption > strong {
    display: inline-block;
    margin-right: 6px;
    vertical-align: top; }
  .content-image figcaption .svg {
    position: relative;
    top: 1px;
    margin-right: 7px;
    fill: #c4c4c4; }

.content-image p {
  display: inline-block; }

@media (min-width: 48em) {
  .content-cta .container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; } }

.content-cta .headline {
  margin-bottom: 8px;
  color: #054f59;
  font-size: 0.625rem; }

.content-cta figure {
  flex-shrink: 0;
  width: 100%; }
  @media (max-width: 47.99em) {
    .content-cta figure {
      margin-bottom: 25px; } }
  @media (min-width: 48em) {
    .content-cta figure {
      max-width: 222px; } }

.content-cta img {
  display: block;
  width: 100%; }

.content-cta .h4 {
  margin-bottom: 12px; }

.content-cta p {
  margin-top: 0; }

.content-cta .button {
  margin-top: 30px; }

.content-cta .link-only {
  width: 100%;
  text-align: center; }

.content-cta--left figure {
  margin-left: 30px; }

.content-cta--right .container {
  flex-direction: row; }

.content-cta--right figure {
  margin-right: 30px; }

@media (min-width: 48em) {
  .content-graph .row {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media print {
  .content-graph .row {
    display: flex;
    justify-content: space-between; } }

.content-graph h2 + .row {
  margin-top: 50px; }
  @media (max-width: 47.99em) {
    .content-graph h2 + .row {
      margin-top: 30px; } }
  @media print {
    .content-graph h2 + .row {
      margin-top: 20px; } }

.content-graph .subtitle {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5rem; }
  @media print {
    .content-graph .subtitle {
      font-size: 0.9375rem; } }

.content-graph .content-wrapper .key-value-list {
  font-size: 1rem; }
  @media print {
    .content-graph .content-wrapper .key-value-list {
      font-size: 0.75rem; } }

.content-graph .graph-wrapper {
  width: 100%; }
  @media (max-width: 47.99em) {
    .content-graph .graph-wrapper {
      margin-top: 20px; } }
  @media (min-width: 48em) {
    .content-graph .graph-wrapper {
      max-width: 408px; } }
  @media print {
    .content-graph .graph-wrapper {
      max-width: 260px; } }
  .content-graph .graph-wrapper p {
    margin-top: 20px; }
    @media print {
      .content-graph .graph-wrapper p {
        margin-top: 10px; } }
  .content-graph .graph-wrapper strong {
    color: #373739; }

.content-graph .key-value-list + p {
  margin-top: 10px; }

.content-graph h2 {
  margin-bottom: 20px;
  color: #ff7549;
  font-size: 2rem; }
  @media print {
    .content-graph h2 {
      margin-bottom: 10px;
      font-size: 1.125rem; } }

.content-graph figure {
  display: block;
  width: 100%;
  border: 1px solid #eaeaea; }

.content-graph img {
  display: block;
  width: 100%; }

.content-specifications h2 {
  margin-bottom: 20px;
  color: #ff7549;
  font-size: 2rem; }
  @media print {
    .content-specifications h2 {
      margin-bottom: 10px;
      font-size: 1.125rem; } }

.content-specifications .key-value-list {
  font-size: 1rem; }
  @media (min-width: 37.5em) {
    .content-specifications .key-value-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  @media print {
    .content-specifications .key-value-list {
      font-size: 0.75rem; } }

.content-specifications .key-value-element {
  width: calc(50% - 10px); }
  .content-specifications .key-value-element:first-child, .content-specifications .key-value-element:nth-child(2) {
    margin-top: 0; }

.page-header {
  position: relative;
  padding-top: 245px;
  padding-bottom: 220px;
  background: #054f59;
  color: #fff; }
  @media (min-width: 37.5em) {
    .page-header {
      overflow: hidden; } }
  @media (max-width: 71.24em) {
    .page-header {
      padding-top: 185px;
      padding-bottom: 160px; } }
  @media (max-width: 37.49em) {
    .page-header {
      padding-top: 170px;
      padding-bottom: 70px; } }
  @media print {
    .page-header {
      padding: 40px 0 30px !important;
      color: #000; }
      .page-header .headline {
        color: rgba(0, 0, 0, 0.4); } }
  .page-header .container {
    position: relative; }
  .page-header:not(.page-header--light)::before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 651px;
    width: 696px;
    background-image: url("../assets/images/page-header/page-header-background.png");
    content: '';
    display: block;
    transform: translate(-50%, -50%) translateX(230px) translateY(110px);
    opacity: .5;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
      .page-header:not(.page-header--light)::before {
        background-image: url("../assets/images/page-header/page-header-background_2x.png");
        background-size: contain; } }
    @media (max-width: 37.49em) {
      .page-header:not(.page-header--light)::before {
        height: 350px;
        width: 375px;
        left: 100%;
        transform: translate(-60%, -50%) translateY(210px); } }
  @media (max-width: 47.99em) {
    .page-header .print-button {
      margin-top: 30px; } }
  @media (min-width: 48em) {
    .page-header .print-button {
      position: absolute;
      right: 0;
      bottom: 12px;
      min-width: 120px; } }
  @media print {
    .page-header .print-button {
      display: none; } }
  .page-header .headline {
    margin-bottom: 6px;
    color: rgba(255, 255, 255, 0.4); }
  .page-header h1 {
    line-height: 1.16; }
    @media (max-width: 71.24em) {
      .page-header h1 br {
        display: none; } }
  .page-header .buttons-wrapper {
    margin-top: 64px; }
    @media (max-width: 23.4375em) {
      .page-header .buttons-wrapper .button {
        padding-right: 10px;
        padding-left: 10px; }
        .page-header .buttons-wrapper .button + .button {
          margin-left: 5px; } }
    .page-header .buttons-wrapper a + a {
      margin-left: 9px; }
  .page-header p {
    margin-top: 20px;
    font-family: "SourceSansPro", sans-serif;
    font-weight: 300;
    font-style: normal;
    max-width: 600px;
    font-size: 1.25rem;
    line-height: 1.5; }
  .page-header .lead {
    margin-top: 20px; }
    .page-header .lead p {
      font-family: "SourceSansPro", sans-serif;
      font-weight: 400;
      font-style: normal;
      max-width: 100%;
      color: #373739;
      font-size: 1.125rem;
      line-height: 2; }

@media (min-width: 71.25em) {
  .page-header + .cards-listing {
    margin-top: -200px; } }

@media (max-width: 71.24em) {
  .page-header + .cards-listing {
    margin-top: -100px; } }

.page-header--light {
  padding-top: 180px;
  padding-bottom: 90px;
  background: transparent;
  color: #000; }
  .page-header--light .headline {
    color: rgba(0, 0, 0, 0.4); }
  .page-header--light::before {
    content: none; }

.page-header--post {
  padding-bottom: 50px; }
  @media (max-width: 47.99em) {
    .page-header--post {
      padding-top: 80px; } }
  @media (min-width: 48em) {
    .page-header--post {
      position: relative; }
      .page-header--post::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        z-index: -2;
        opacity: .24;
        background-image: url("../assets/images/dots.png");
        background-size: 64px 64px; } }
  .page-header--post h1 {
    color: #ff7549; }
    @media (max-width: 47.99em) {
      .page-header--post h1 {
        font-size: 2.5rem; } }
  .page-header--post .container {
    padding-top: 40px;
    position: relative; }
    .page-header--post .container::before {
      position: absolute;
      top: 0;
      left: -188px;
      height: calc(100% + 300px);
      width: calc(100% + 2*188px);
      content: '';
      z-index: -1;
      background-color: #fff; }

.cards-listing {
  position: relative;
  padding-bottom: 25px; }
  .cards-listing::before {
    position: absolute;
    top: 50px;
    left: 50%;
    height: calc(100% - 100px);
    width: 750px;
    content: '';
    z-index: 0;
    transform: translateX(-50%);
    opacity: .24;
    background-image: url("../assets/images/dots.png");
    background-size: 64px 64px; }
  .cards-listing .container {
    position: relative; }
    @media (min-width: 48em) {
      .cards-listing .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; } }
    @media (min-width: 71.25em) {
      .cards-listing .container {
        justify-content: space-between; } }
  .cards-listing .card {
    width: 100%;
    max-width: 345px; }
    @media (min-width: 71.25em) {
      .cards-listing .card:nth-child(n + 4) {
        margin-top: 40px; } }
    @media (min-width: 48em) and (max-width: 71.24em) {
      .cards-listing .card {
        width: calc(50% - 20px); }
        .cards-listing .card:nth-child(n + 2) {
          margin-top: 20px; }
        .cards-listing .card:not(.card--placeholder):not(:nth-child(3n)) + .card {
          margin-left: 20px; } }
    @media (max-width: 47.99em) {
      .cards-listing .card {
        margin-right: auto;
        margin-left: auto; }
        .cards-listing .card + .card {
          margin-top: 20px; } }
    .cards-listing .card:nth-child(n + 4) {
      margin-top: 40px; }
  .cards-listing .card--placeholder {
    opacity: 0; }
    @media (max-width: 71.24em) {
      .cards-listing .card--placeholder {
        display: none; } }

.article .article-date {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal;
  padding-top: 21px;
  padding-bottom: 21px;
  margin-bottom: 50px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  color: #054f59;
  font-size: 0.625rem;
  letter-spacing: 2px;
  line-height: 1.05;
  text-transform: uppercase; }

.article .bottom-button {
  margin-top: 50px;
  text-align: center; }

.article .article-intro {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem; }
  @media print {
    .article .article-intro {
      padding-bottom: 30px;
      border-bottom: 1px solid #e1e1e1;
      font-size: 0.75rem; } }

.content + .content {
  margin-top: 75px; }
  @media print {
    .content + .content {
      margin-top: 30px; } }

.content.content-text + .content-text {
  margin-top: 40px; }

.cta-blocks {
  padding-top: 75px;
  padding-bottom: 75px;
  color: #fff; }
  @media (max-width: 47.99em) {
    .cta-blocks {
      padding-top: 0; } }
  @media (min-width: 37.5em) {
    .cta-blocks .cta-blocks-wrapper {
      display: flex;
      justify-content: space-between; } }
  .cta-blocks article {
    width: 100%;
    max-width: calc(50% - 22px); }
    @media (max-width: 37.49em) {
      .cta-blocks article {
        max-width: 100%; }
        .cta-blocks article + article {
          margin-top: 70px; } }
  .cta-blocks .headline {
    margin-bottom: 7px;
    color: rgba(255, 255, 255, 0.4); }
  .cta-blocks h2 + p {
    margin-top: 50px; }
    @media (max-width: 37.49em) {
      .cta-blocks h2 + p {
        margin-top: 40px; } }

.cta-blocks-button {
  margin-top: 70px;
  display: flex;
  align-items: center; }
  @media (min-width: 37.5em) {
    .cta-blocks-button::before, .cta-blocks-button::after {
      height: 1px;
      width: 100%;
      content: '';
      display: block;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.1); } }
  .cta-blocks-button .button {
    flex-shrink: 0; }
    @media (min-width: 37.5em) {
      .cta-blocks-button .button {
        margin-right: 32px;
        margin-left: 32px; } }

.latest-news {
  margin-top: 80px; }
  @media (min-width: 48em) {
    .latest-news {
      margin-top: 120px; } }

.latest-news-header {
  margin-bottom: 30px;
  text-align: center; }
  @media (min-width: 37.5em) {
    .latest-news-header {
      margin-bottom: 70px; } }
  .latest-news-header .headline {
    color: #a8a8a8; }
  .latest-news-header h2 {
    line-height: 1.15; }
    @media (max-width: 37.49em) {
      .latest-news-header h2 {
        font-size: 3.75rem; } }

.latest-news-list {
  display: flex;
  justify-content: space-between; }
  .latest-news-list .line {
    margin: -38px 20px;
    display: block;
    border-right: 1px dashed rgba(225, 225, 225, 0.5); }
    @media (max-width: 47.99em) {
      .latest-news-list .line {
        display: none; } }

.latest-news-footer {
  margin-top: 65px;
  text-align: center; }
  @media (min-width: 37.5em) {
    .latest-news-footer {
      margin-top: 110px; } }

@media (max-width: 71.24em) {
  .news-listing .news-card {
    margin-right: auto;
    margin-left: auto; } }

.news-listing .news-card + .news-card {
  margin-top: 65px; }

.news-listing-list {
  position: relative; }
  .news-listing-list::before, .news-listing-list::after {
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    border-right: 1px dashed #dadada; }
    @media (max-width: 37.49em) {
      .news-listing-list::before, .news-listing-list::after {
        display: none; } }
  @media (min-width: 71.25em) {
    .news-listing-list::before {
      transform: translateX(-204px); } }
  @media (max-width: 71.24em) {
    .news-listing-list::after {
      display: none; } }
  @media (min-width: 71.25em) {
    .news-listing-list::after {
      transform: translateX(204px); } }
  @media (min-width: 71.25em) {
    .news-listing-list > div:nth-of-type(2) .news-card {
      margin-right: auto;
      margin-left: auto; }
    .news-listing-list > div:last-of-type .news-card {
      margin-left: auto; } }

.contact-us-blocks {
  padding-bottom: 30px; }
  @media (min-width: 48em) {
    .contact-us-blocks .container {
      display: flex;
      justify-content: space-between; }
      .contact-us-blocks .container .card {
        width: 100%;
        max-width: calc(50% - 16px); } }
  @media (max-width: 47.99em) {
    .contact-us-blocks .container .card + .card {
      margin-top: 40px; } }
  @media (min-width: 71.25em) {
    .contact-us-blocks .card-inner {
      padding: 80px 60px; } }

.catalogue {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 47.99em) {
    .catalogue {
      padding-top: 60px;
      padding-bottom: 0; } }
  @media (min-width: 48em) {
    .catalogue .catalogue-text-wrapper {
      display: flex;
      justify-content: space-between; } }
  .catalogue .catalogue-text-wrapper p {
    font-size: 1rem; }
  .catalogue .line {
    margin-right: 54px;
    margin-left: 54px;
    flex-shrink: 0;
    width: 1px;
    background-color: #e1e1e1; }
    @media (max-width: 47.99em) {
      .catalogue .line {
        display: none; } }
  .catalogue .catalogue-text-left {
    flex-shrink: 0;
    width: 100%; }
    @media (min-width: 48em) {
      .catalogue .catalogue-text-left {
        max-width: 323px; } }
    @media (max-width: 47.99em) {
      .catalogue .catalogue-text-left {
        margin-bottom: 40px; } }
    .catalogue .catalogue-text-left h2 {
      margin-bottom: 20px;
      font-size: 2.5rem; }
  .catalogue .orange,
  .catalogue .blue {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal; }
  .catalogue .catalogue-text-right h3 {
    margin-bottom: 10px;
    font-size: 1.5rem; }
  .catalogue .catalogue-text-right p + h3 {
    margin-top: 30px; }
  .catalogue .catalogue-table-wrapper {
    margin-top: 85px;
    margin-bottom: 50px;
    min-height: 360px;
    max-height: 650px;
    overflow: auto; }
    @media (max-width: 47.99em) {
      .catalogue .catalogue-table-wrapper {
        margin-top: 35px;
        height: 90vh; } }
    .catalogue .catalogue-table-wrapper thead {
      background-color: white; }
    .catalogue .catalogue-table-wrapper tbody {
      width: 100%; }
    .catalogue .catalogue-table-wrapper table {
      width: 100%;
      min-width: 1100px;
      font-size: 0.8125rem; }
      .catalogue .catalogue-table-wrapper table.fixed-head .fltrow .custom-select-container {
        visibility: hidden; }
  .catalogue .catalogue-ctas {
    margin-top: 90px; }
    @media (max-width: 47.99em) {
      .catalogue .catalogue-ctas {
        margin-top: 45px; } }
    @media (max-width: 23.4375em) {
      .catalogue .catalogue-ctas .button {
        padding-right: 10px;
        padding-left: 10px; }
        .catalogue .catalogue-ctas .button + .button {
          margin-left: 5px; } }
    .catalogue .catalogue-ctas a + a {
      margin-left: 9px; }
  .catalogue table.TF .fltrow select {
    padding-bottom: 2px;
    font-weight: 600;
    line-height: 1.25; }
  .catalogue .custom-select-opener {
    padding: 5px 0;
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    height: auto;
    border: 0;
    color: #373739; }
    .catalogue .custom-select-opener span {
      position: relative;
      padding-right: 30px; }
      .catalogue .custom-select-opener span::before {
        height: 6px;
        width: 11px;
        position: absolute;
        top: calc(50% - 3px);
        right: 10px;
        content: '';
        background-image: url("../assets/images/icons/arrow-down.png");
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none; }

.search-blocks {
  position: relative;
  padding-bottom: 30px; }
  @media (min-width: 48em) {
    .search-blocks .container {
      display: flex;
      justify-content: space-between; }
      .search-blocks .container .card {
        width: 100%;
        max-width: calc(50% - 16px); } }
  @media (max-width: 47.99em) {
    .search-blocks .container .card + .card {
      margin-top: 40px; } }
  @media (min-width: 71.25em) {
    .search-blocks .card-inner {
      padding: 80px 60px; } }
  .search-blocks .card-inner h2 {
    min-height: 0; }

.results {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-top: 65px;
  padding-bottom: 65px;
  max-width: 1400px;
  background-color: #fff; }
  @media (max-width: 47.99em) {
    .results {
      padding-top: 30px;
      padding-bottom: 30px; } }

[template="home"] .gradient-wrapper {
  padding-bottom: 245px;
  background: linear-gradient(-180deg, #054f59 8%, #00444e 99%); }
  @media (max-width: 47.99em) {
    [template="home"] .gradient-wrapper {
      padding-bottom: 180px; } }

[template="home"] .page-header {
  padding-top: 280px;
  padding-bottom: 160px;
  background: transparent; }
  @media (min-width: 48em) {
    [template="home"] .page-header::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 1px;
      width: 100%;
      content: '';
      max-width: 1100px;
      transform: translateX(-50%);
      border-bottom: 1px dashed rgba(255, 255, 255, 0.1); } }
  @media (max-width: 71.24em) {
    [template="home"] .page-header::after {
      max-width: 90%; } }
  @media (max-width: 37.49em) {
    [template="home"] .page-header {
      padding-top: 170px;
      padding-bottom: 120px; } }
  @media (min-width: 48em) {
    [template="home"] .page-header::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) translateX(230px) translateY(50px); } }
  [template="home"] .page-header .headline {
    margin-bottom: 22px; }
  @media (max-width: 23.4375em) {
    [template="home"] .page-header .buttons-wrapper .button {
      padding-right: 10px;
      padding-left: 10px; }
    [template="home"] .page-header .buttons-wrapper .button + .button {
      margin-left: 5px; } }

[template="home"] .cards-listing {
  margin-top: -200px; }

@media (max-width: 47.99em) {
  [template="404"] .page-header {
    padding-bottom: 140px; } }

[template="404"] .page-header .subtitle {
  max-width: 340px; }

@media (min-width: 48em) {
  [template="get-a-quote"] .page-header {
    padding-top: 180px;
    padding-bottom: 280px; } }

[template="get-a-quote"] .get-a-quote-form {
  padding-top: 50px;
  position: relative;
  z-index: 2;
  background-color: #fff; }
  @media (min-width: 48em) {
    [template="get-a-quote"] .get-a-quote-form {
      padding-top: 100px;
      margin-top: -200px;
      margin-right: auto;
      margin-left: auto;
      max-width: 96%; } }
  @media (min-width: 90em) {
    [template="get-a-quote"] .get-a-quote-form {
      max-width: 1440px; } }

[template="get-a-quote"] .ui-form,
[template="get-a-quote"] .wpcf7-form {
  margin-right: auto;
  margin-left: auto;
  max-width: 910px; }
  [template="get-a-quote"] .ui-form .lead,
  [template="get-a-quote"] .wpcf7-form .lead {
    margin-bottom: 20px;
    font-size: 1.125rem; }

[template="get-a-quote"] .products-selection {
  padding-top: 70px;
  padding-bottom: 50px; }

[template="get-a-quote"] .product-fieldset + .product-fieldset {
  margin-top: 80px; }

@media (min-width: 37.5em) {
  [template="get-a-quote"] .product-fieldset-row {
    display: flex; }
    [template="get-a-quote"] .product-fieldset-row:first-child {
      justify-content: space-between; }
    [template="get-a-quote"] .product-fieldset-row .form-element {
      max-width: 93px; }
      [template="get-a-quote"] .product-fieldset-row .form-element:first-child {
        max-width: 240px; }
      [template="get-a-quote"] .product-fieldset-row .form-element:nth-child(3) {
        max-width: 225px; } }

@media (min-width: 37.5em) and (max-width: 47.99em) {
  [template="get-a-quote"] .product-fieldset-row {
    flex-wrap: wrap; }
    [template="get-a-quote"] .product-fieldset-row .form-element {
      width: calc(33% - 10px);
      max-width: 100%; }
      [template="get-a-quote"] .product-fieldset-row .form-element:nth-last-child(1), [template="get-a-quote"] .product-fieldset-row .form-element:nth-last-child(2), [template="get-a-quote"] .product-fieldset-row .form-element:nth-last-child(3) {
        margin-top: 20px; } }

[template="get-a-quote"] .product-fieldset-row + .product-fieldset-row {
  margin-top: 50px; }

@media (min-width: 37.5em) {
  [template="get-a-quote"] .checkbox-wrapper + .checkbox-wrapper {
    margin-left: 42px; } }

[template="get-a-quote"] .product-fieldset-header {
  margin-bottom: 45px;
  position: relative;
  padding: 19px 34px;
  border: 1px solid #dadada;
  background-color: #fafafa; }
  [template="get-a-quote"] .product-fieldset-header .title {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #373739;
    font-size: 1rem;
    line-height: 1; }
  [template="get-a-quote"] .product-fieldset-header .remove-product {
    position: absolute;
    top: 0;
    right: 0;
    height: 56px;
    width: 56px;
    color: #7dc5b1;
    line-height: 0; }
    [template="get-a-quote"] .product-fieldset-header .remove-product::before {
      content: '—';
      font-size: 0.625rem; }

[template="get-a-quote"] .add-more-products {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 40px;
  font-family: "SourceSansPro", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #7dc5b1;
  font-size: 0.875rem; }

[template="get-a-quote"] [type="submit"] {
  min-width: 167px; }

[template="contact"] .page-header {
  padding-top: 180px;
  padding-bottom: 280px; }

[template="contact"] .contact-us-blocks {
  position: relative;
  margin-top: -200px; }

[template="contact"] .card p {
  font-size: 1rem; }

@media (min-width: 71.25em) {
  [template="assay-list"] .page-header {
    padding-top: 210px;
    padding-bottom: 130px; } }

@media (min-width: 71.25em) {
  [template="assay-list"] .page-header::before {
    transform: translate(-50%, -50%) translateX(370px) translateY(250px); } }

[template="search"] .page-header {
  padding-top: 129px;
  padding-bottom: 0; }
  @media (max-width: 47.99em) {
    [template="search"] .page-header {
      padding-top: 80px; } }
  [template="search"] .page-header + .search-blocks,
  [template="search"] .page-header + .results {
    margin-top: -168px; }
  [template="search"] .page-header h2 {
    font-family: "SourceSansPro", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 40px;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center; }
  [template="search"] .page-header::before {
    content: none; }
  [template="search"] .page-header .container {
    position: relative;
    padding-top: 64px;
    padding-bottom: 208px; }
    @media (min-width: 71.25em) {
      [template="search"] .page-header .container {
        padding-right: 156px;
        padding-left: 156px; } }
    [template="search"] .page-header .container::before {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: calc(100% + 104px);
      content: '';
      transform: translateX(-50%);
      opacity: .4;
      background-image: url("../assets/images/dots.png");
      background-size: 64px 64px; }
  [template="search"] .page-header .search-input-wrapper {
    position: relative;
    z-index: 2;
    border: 0;
    border-bottom: 2px solid #043c43;
    border-radius: 0;
    background-color: #064a53; }
  [template="search"] .page-header input {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    color: #fff; }
    @media (max-width: 47.99em) {
      [template="search"] .page-header input {
        font-size: 1.25rem; } }
    [template="search"] .page-header input::placeholder {
      color: rgba(255, 255, 255, 0.2); }
  [template="search"] .page-header .search-submit::before {
    background-color: rgba(255, 255, 255, 0.1); }
  [template="search"] .page-header .search-submit .svg {
    fill: #fff; }

@media (min-width: 48em) {
  [template="assay-detail"] .page-header {
    padding-top: 180px;
    padding-bottom: 370px; } }

@media (min-width: 48em) {
  [template="assay-detail"] .page-header h1 {
    padding-right: 200px; } }

[template="assay-detail"] .content {
  margin-top: 0; }
  [template="assay-detail"] .content .container {
    padding-top: 80px;
    padding-bottom: 80px; }
    @media print {
      [template="assay-detail"] .content .container {
        padding-top: 30px;
        padding-bottom: 30px; } }
    @media (max-width: 47.99em) {
      [template="assay-detail"] .content .container {
        padding-top: 40px;
        padding-bottom: 40px; } }
  [template="assay-detail"] .content + .content .container {
    border-top: 1px solid #e1e1e1; }

[template="assay-detail"] .article-start + .contents-wrapper .content:first-child .container {
  padding-top: 0; }

[template="assay-detail"] .article {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
  width: 100%;
  max-width: 1100px;
  background-color: white; }
  @media (min-width: 48em) {
    [template="assay-detail"] .article {
      padding-top: 120px;
      margin-top: -282px; } }
  @media print {
    [template="assay-detail"] .article {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0; } }

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/SourceSansPro-Regular.woff2") format("woff2"), url("../assets/fonts/SourceSansPro-Regular.woff") format("woff"); }

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/SourceSansPro-Light.woff2") format("woff2"), url("../assets/fonts/SourceSansPro-Light.woff") format("woff"); }

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/SourceSansPro-SemiBold.woff2") format("woff2"), url("../assets/fonts/SourceSansPro-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/SourceSansPro-LightItalic.woff2") format("woff2"), url("../assets/fonts/SourceSansPro-LightItalic.woff") format("woff"); }
