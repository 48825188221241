.pagination {
  @include margin(90px null null null);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(12px);
  letter-spacing: 2px;
  text-transform: uppercase;

  .svg {
    fill: color(cyan);
  }

  > a {
    @include font('sans-serif', 'semibold');
    flex-shrink: 0;
    color: color(dark-green);

    + a {
      margin-left: 75px;

      @include mq($until: 'm') {
        margin-left: 0;
      }
    }

    .svg {
      transition: transform .2s $ease-out-quad;
    }
  }

  .pagination-next:hover {
    .svg {
      transform: translateX(8px);
    }
  }

  .pagination-prev:hover {
    .svg {
      transform: translateX(-8px);
    }
  }

  .first,
  .last {
    @include mq($until: 'm') {
      display: none;
    }
  }
}

.pagination-list {
  @include padding(null 30px);
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  span {
    @include position(relative);
    @include padding(null 10px);
    display: block;
    color: color(dark-grey);

    @include mq($until: 'm') {
      @include padding(null 5px);
    }
  }

  a::before,
  .current::before {
    @include position(absolute, null null 0 calc(50% - 1px));
    @include size(calc(100% - 20px), 2px);
    content: '';
    transform: translateX(-50%);
    transition: opacity .2s $ease-out-quad;
    opacity: 0;
    background-color: color(cyan);

    @include mq($until: 'm') {
      width: calc(100% - 10px);
    }
  }

  a:hover::before {
    opacity: 1;
  }

  .current {
    @include font('sans-serif', 'semibold');

    &::before {
      opacity: 1;
    }
  }
}
